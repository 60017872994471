'use client'

import { Button } from '@design-system/src/components/Button'
import { CustomDivider } from '@design-system/src/components/CustomDivider'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { CustomGrid } from '@design-system/src/components/CustomGrid'
import { Text } from '@design-system/src/components/Text'
import { PropsWithChildren, useCallback, useRef, useState } from 'react'
import { API_ROUTES_NEXT_PUBLIC_SITE } from 'src/utils/route-utils'
import { Box } from 'styled-system/jsx'

import {
  IMAGE_PLACEHOLDER_SIZES,
  IMAGE_PLACEHOLDER_SIZES_TWO_COLUMNS_VARIANT,
  IMAGE_SIZES,
  IMAGE_SIZES_TWO_COLUMNS_VARIANT,
  dividerStyled,
} from '.'
import { ArticlePreviewHorizontal, ArticlePreviewHorizontalType } from '../ArticlePreviewHorizontal'
import { GoogleAdProps } from '../GoogleAd'
import { DynamicAd } from './DynamicAd'
import { horizontalCollapsableId } from './index'

type LoadMoreProps = {
  initialOffset: string | null | undefined
  adIsVisible: boolean
  blockId: string | null | undefined
  hideAuthors?: boolean
  type: 'externalBlocks' | 'additionalBlocks'
  imgPlaceholder?: string
  imgPlaceholderHeight?: number
  imgPlaceholderWidth?: number
  googleAdHorizontalConfig?: GoogleAdProps | undefined
  horizontalAdIsVisible?: boolean
  loadMoreInitiallyDisabled: boolean
}

export const LoadMoreArticles = ({
  initialOffset,
  adIsVisible,
  hideAuthors,
  blockId,
  type,
  imgPlaceholder,
  imgPlaceholderHeight,
  imgPlaceholderWidth,
  googleAdHorizontalConfig,
  horizontalAdIsVisible,
  loadMoreInitiallyDisabled, // TODO: Need to fix this https://app.asana.com/0/1206684075789442/1208509238799960
}: PropsWithChildren<LoadMoreProps>) => {
  const [loadMoreNodes, setLoadMoreNodes] = useState<ArticlePreviewHorizontalType[]>([])
  const currentOffsetRef = useRef<string | undefined | null>(initialOffset)
  const [loading, setLoading] = useState(false)
  const isTwoColumns = adIsVisible
  const sizes = isTwoColumns ? IMAGE_SIZES_TWO_COLUMNS_VARIANT : IMAGE_SIZES
  const placeholderSizes = isTwoColumns ? IMAGE_PLACEHOLDER_SIZES_TWO_COLUMNS_VARIANT : IMAGE_PLACEHOLDER_SIZES
  // const [disableLoadMore, setDisableLoadMore] = useState(loadMoreInitiallyDisabled || false) // TODO: this doeen't always work because sometimes there are still more articles but not many show up initially. https://app.asana.com/0/1206684075789442/1208509238799960
  const [disableLoadMore, setDisableLoadMore] = useState(false)

  const loadMore = useCallback(async () => {
    setLoading(true)
    const params = new URLSearchParams({
      nextToken: currentOffsetRef.current || '',
      blockId: blockId || '',
      type: type,
    })
    await fetch(API_ROUTES_NEXT_PUBLIC_SITE.loadMoreArticlesApiUrl + '?' + params.toString())
      .then(res => {
        if (res.ok) {
          return res.json()
        }
        throw new Error('Error fetching data')
      })
      .then(res => {
        const [node, next] = res as [ArticlePreviewHorizontalType[], string]
        setLoadMoreNodes(prev => {
          const deduped = prev.filter(el => !node.some(n => n.articleHref === el.articleHref))
          return [...deduped, ...node]
        })
        if (!next) {
          currentOffsetRef.current ??= undefined
          setDisableLoadMore(true)
          // no more stories to load
          return
        }

        currentOffsetRef.current = next
      })
      .catch(() => {})
      .finally(() => setLoading(false))
  }, [blockId, type])

  return (
    <>
      <CustomGrid
        css={{
          bp1ToBp2: {
            mt: '$8',
          },
          bp2ToBp3: {
            mt: isTwoColumns ? '$0' : '$8',
          },
        }}
        columns={{ base: '1', bp1: '2', bp2: isTwoColumns ? '1' : '2', bp3: '1' }}
        gap={{
          base: 'none',
          bp1: 'large',
          bp2: isTwoColumns ? 'none' : 'large',
          bp3: 'none',
        }}>
        {loadMoreNodes?.map((el, index) => {
          const showAd = googleAdHorizontalConfig && horizontalAdIsVisible && (index - 1) % 10 === 0
          return (
            <div key={el.articleHref}>
              {showAd && (
                <Box css={{ display: 'grid' }} className={`${new Date().toString()}`}>
                  <CustomDivider className={dividerStyled({ isTwoColumns })} size="small" orientation="horizontal" />
                  <Box css={{ overflowX: 'auto', display: 'grid' }}>
                    <DynamicAd
                      googleAdHorizontalConfig={googleAdHorizontalConfig}
                      horizontalCollapsableId={horizontalCollapsableId}
                    />
                  </Box>
                </Box>
              )}
              <CustomFlex direction="column" key={el.articleHref}>
                <CustomDivider className={dividerStyled({ isTwoColumns })} size="small" orientation="horizontal" />
                <ArticlePreviewHorizontal
                  imgPlaceholder={imgPlaceholder}
                  imgPlaceholderHeight={imgPlaceholderHeight}
                  imgPlaceholderWidth={imgPlaceholderWidth}
                  {...el}
                  hideAuthors={hideAuthors}
                  wrapSectionWithLink={false}
                  sizes={sizes}
                  placeholderSizes={placeholderSizes}
                />
              </CustomFlex>
            </div>
          )
        })}
      </CustomGrid>
      <CustomDivider css={{ my: '$10' }} />
      <CustomFlex justify="center" css={{ my: '$10' }} direction={'column'} align={'center'}>
        <Button
          onClick={loadMore}
          label="Load More"
          variant="secondary"
          buttonState={loading ? 'waiting' : 'default'}
          disabled={disableLoadMore}
        />
        {disableLoadMore && (
          <Text css={{ mt: '$2', color: '$gs11' }} variant="body2">
            You have reached the end of the stories.
          </Text>
        )}
      </CustomFlex>
    </>
  )
}
