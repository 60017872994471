import Image from 'next/image'
import { FC } from 'react'
import { css } from 'styled-system/css'

import { Button } from './Button'
import { LoadingIndicator } from './LoadingIndicator'
import { Text } from './Text'

export type WaitingProps = {
  message?: string
  secondaryMessage?: string
  children?: React.ReactNode
  logoDetails?: {
    logoSrc: any
    logoWidth: number
    logoHeight: number
  } | null
  buttonText?: string
  buttonCallback?: () => void
  loadingIndicatorIsDisabled?: boolean
}

export const Waiting: FC<WaitingProps> = ({
  logoDetails,
  message = '',
  secondaryMessage,
  buttonText,
  buttonCallback,
  loadingIndicatorIsDisabled,
}) => (
  <>
    <div className={backgroundContainer}>
      <div>
        {logoDetails && (
          <Image
            src={logoDetails.logoSrc}
            alt="App Logo"
            height={logoDetails.logoHeight}
            width={logoDetails.logoWidth}
            priority={true}
          />
        )}
      </div>
      {message && (
        <Text variant="body1" css={css.raw({ mt: '$6' })}>
          {message}
        </Text>
      )}
      {secondaryMessage && (
        <Text variant="body2" css={css.raw({ mt: '$4' })}>
          {secondaryMessage}
        </Text>
      )}
      {buttonText && buttonCallback && (
        <Button
          variant="primary"
          onClick={buttonCallback}
          label={buttonText}
          css={css.raw({
            mt: '$6',
          })}
        />
      )}
    </div>
    {!loadingIndicatorIsDisabled && (
      <LoadingIndicator
        palette="grayscale"
        size="large"
        css={css.raw({
          position: 'fixed',
          top: '$0',
          left: '$0',
          right: '$0',
        })}
      />
    )}
  </>
)

const backgroundContainer = css({
  position: 'fixed',
  inset: '$0',
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  backgroundColor: '$gs2',
  color: '$gs12',
})
