'use client'

import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Gutter } from '@design-system/src/components/Gutter'
import { Text } from '@design-system/src/components/Text'
import { FC, useState } from 'react'

import { LoginType, signInUser } from '.'

export const MagicLinkSentMessage: FC<{
  customerServiceNumber?: string
  submittedEmail: string | null
  setLoginType: React.Dispatch<React.SetStateAction<LoginType>>
}> = ({ customerServiceNumber, submittedEmail, setLoginType }) => {
  const [showError, setShowError] = useState(false)

  return (
    <Gutter css={{ paddingLeft: '$0 !important' }}>
      <Text css={{ color: '$gs12' }} variant="subtitle1">
        We just emailed a magic link to <b>{submittedEmail}</b>. Once it arrives, it will be valid for 24 hours.
        {customerServiceNumber ? ' Call the number below if not received.' : ''}
      </Text>
      {submittedEmail && (
        <Text variant="body2" css={{ color: '$gs12', mt: '$7' }}>
          Trouble signing in? Use a{' '}
          <Text
            variant="body2"
            isLink={true}
            css={{ color: '$gs11' }}
            onClick={async () => {
              try {
                setShowError(false)
                await signInUser({ email: submittedEmail, type: 'code' })
                setLoginType('code')
              } catch (error) {
                console.error('error signing in:', error)
                setShowError(true)
              }
            }}>
            verification code
          </Text>{' '}
          instead.
        </Text>
      )}

      {showError && (
        <CustomFlex
          css={{ backgroundColor: '$ale', mt: '$4', py: '$2', borderRadius: '$3' }}
          justify="center"
          align="center">
          <Text css={{ color: '$aleText', textAlign: 'center' }} variant="caption">
            Sorry, an error occured while sending the sign in code. Please try again later.
          </Text>
        </CustomFlex>
      )}
    </Gutter>
  )
}
