'use client'

import { CustomDivider } from '@design-system/src/components/CustomDivider'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Text } from '@design-system/src/components/Text'
import { useRouter } from 'next-nprogress-bar'
import React from 'react'

import { BlockPubAuthorOverviewCustomPageData } from '../../../_utils/typescript-utils'

export const NavigationSection: React.FC<{
  biography: BlockPubAuthorOverviewCustomPageData['biography']
  hasContactSection: boolean
}> = ({ biography, hasContactSection }) => {
  const router = useRouter()

  const onClickAnchor = anchorId => event => {
    event.preventDefault() // Prevent the default anchor click behavior
    // Find the element with the given ID
    const element = document.getElementById(anchorId)
    if (element) {
      const elHeaderHeight = document
        .querySelector('[data-sho-block-name=BlockPubHeaderMenu-HeaderMain]')
        ?.getBoundingClientRect()?.height
      const yOffset = elHeaderHeight ? -elHeaderHeight : -140
      const yPosition = element.getBoundingClientRect().top + window.scrollY + yOffset
      window.scrollTo({ top: yPosition, behavior: 'smooth' })
      // router.push(`#${anchorId}`)
      router.replace(`#${anchorId}`, { scroll: false })
    }
  }

  const numberOfSections = [biography, hasContactSection, 'Latest'].filter(Boolean).length

  return numberOfSections > 1 ? (
    <>
      <CustomDivider css={darkDivider} />

      <CustomFlex gap={'5'}>
        <CustomFlex gap={'5'} direction={'row'}>
          {biography && (
            <Text variant={'body2'} isLink={true} onClick={onClickAnchor('about')}>
              About
            </Text>
          )}
          {hasContactSection && (
            <Text variant={'body2'} isLink={true} onClick={onClickAnchor('contact')}>
              Contact
            </Text>
          )}
          <Text variant={'body2'} isLink={true} onClick={onClickAnchor('blockPubAuthorRelatedArticles')}>
            Latest
          </Text>
        </CustomFlex>
      </CustomFlex>
      <CustomDivider css={darkDivider} />
    </>
  ) : null
}

const darkDivider = {
  backgroundColor: '$gs12',
} as const
