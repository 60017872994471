'use client'

import { CustomDivider } from '@design-system/src/components/CustomDivider'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { CustomGrid } from '@design-system/src/components/CustomGrid'
import { Gutter } from '@design-system/src/components/Gutter'
import { Text } from '@design-system/src/components/Text'
import { WysiwygReader } from '@design-system/src/components/WysiwygReader'
import { loadStripe } from '@stripe/stripe-js/pure'
import { useSession } from 'next-auth/react'
import { BlockPubSubscribeCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import { useSearchParams } from 'next/navigation'
import React, { useMemo, useState } from 'react'
import { API_ROUTES_NEXT_PUBLIC_SITE } from 'src/utils/route-utils'
import { cva } from 'styled-system/css'

import {
  getSubscriptionIdForPublication,
  getSubscriptionRecurrence,
  getUserSubscriptionPlan,
  isUserSubscribedToNewsletter,
  isUserSubscribedToPublication,
} from '../../../../_utils/publication-utils'
import { BlockViewProps } from '../../../../_utils/typescript-utils'
import { BlockModal } from '../../../BlockModal'
import { StripePromos } from '../../../StripePromos'
import { CancelSubscriptionModal } from '../SubscibeShared/CancelSubscriptionModal'
import { CancelationFormInput, CancelationReasonModal } from '../SubscibeShared/CancelationReasonModal'
import { ConfirmSubscriptionCancelModal } from '../SubscibeShared/ConfirmSubscriptionCancelModal'
import { StripePlanCard } from '../SubscribeStripe/StripePlanCard'

export const SubscribeStripe: React.FC<
  BlockViewProps<{
    ShapeOfCustomPropsDerivedFromPageData: BlockPubSubscribeCustomPageData
  }>
> = props => {
  const searchParams = useSearchParams()
  const {
    discountPromotionalText,
    description,
    applyDiscountApiUrl,
    subscribeToNewsletterApiUrl,
    discountCode,
    discountApplicationSuccessText,
    newsletterId,
    createSubscriberWithStripeAccountApiUrl,
    updateSubscriberWithStripeAccountApiUrl,
    stripePublishableKey,
    stripePlans,
    stripeSupportPlans,
    propayPlans,
    connectedSubscriptionSystem,
    disclaimer,
    stripePlanOrderByPriceId,
    stripePromotions,
  } = props.blockCustomData

  const { data: session, status } = useSession()
  // console.log('stripePlans', stripePlans)
  // console.log('stripePlanOrderByPriceId', stripePlanOrderByPriceId)
  // console.log('session', session)

  const availableStripePlansOrderedByPriceId = stripePlanOrderByPriceId?.filter(priceId =>
    stripePlans.some(plan => plan.stripePriceIdMonthly === priceId || plan.stripePriceIdYearly === priceId),
  )

  // special case where 1 plan but with both Monthly and Yearly options
  const isSpecialCase1PlanWithBothOptions = useMemo(() => {
    if (availableStripePlansOrderedByPriceId?.length !== 2) return false
    const planIdOfFirstPlan = stripePlans.find(
      plan =>
        plan.stripePriceIdMonthly === availableStripePlansOrderedByPriceId[0] ||
        plan.stripePriceIdYearly === availableStripePlansOrderedByPriceId[0],
    )?.productId
    const planIdOfSecondPlan = stripePlans.find(
      plan =>
        plan.stripePriceIdMonthly === availableStripePlansOrderedByPriceId[1] ||
        plan.stripePriceIdYearly === availableStripePlansOrderedByPriceId[1],
    )?.productId
    return planIdOfFirstPlan === planIdOfSecondPlan
  }, [])
  const firstPlanInfo = availableStripePlansOrderedByPriceId
    ? stripePlans.find(
        plan =>
          plan.stripePriceIdMonthly === availableStripePlansOrderedByPriceId[0] ||
          plan.stripePriceIdYearly === availableStripePlansOrderedByPriceId[0],
      )
    : undefined

  const subscribedPlanRecurrence = getSubscriptionRecurrence({
    status,
    session,
    connectedSubscriptionSystem,
    stripePlans,
    stripeSupportPlans,
  })

  const [isCancelReasonModalOpen, setIsOpenCancelReasonModal] = useState(false)
  const [isCancelSubscriptionModalOpen, setIsCancelSubscriptionModalOpen] = useState(false)
  const [subscriptionCancelledModalOpen, setSubscriptionCancelledModalOpen] = useState(false)

  const [thankyouModalOpen, setThankyouModalOpen] = useState(false)
  const [feedbackFormReason, setFeedbackFormReason] = useState<CancelationFormInput>()

  const isUserSubscribed = isUserSubscribedToPublication({
    status,
    session,
    connectedSubscriptionSystem,
    stripePlans,
    stripeSupportPlans,
  })
  const subscriptionId = getSubscriptionIdForPublication({
    status,
    session,
    connectedSubscriptionSystem,
    stripePlans,
    stripeSupportPlans,
  })
  const subscribedUserPlan = getUserSubscriptionPlan({
    session,
    connectedSubscriptionSystem,
    stripePlans,
    stripeSupportPlans,
    propayPlans,
  })
  const isUserSubscribedNewsLetter = isUserSubscribedToNewsletter(status, session, newsletterId)
  const showPromo = !isUserSubscribed && status !== 'loading' // don't want to incentivize users to change for cheaper plans

  const availablePlanPriceIdsOrderedFromMostToLeastImportant =
    availableStripePlansOrderedByPriceId?.length === 3
      ? [
          availableStripePlansOrderedByPriceId[1],
          availableStripePlansOrderedByPriceId[0],
          availableStripePlansOrderedByPriceId[2],
        ]
      : availableStripePlansOrderedByPriceId

  return (
    <>
      {showPromo && (
        <StripePromos
          stripePromotions={stripePromotions}
          availablePlanPriceIdsOrderedFromMostToLeastImportant={availablePlanPriceIdsOrderedFromMostToLeastImportant}
          stripePlans={stripePlans}
          status={status}
          loadStripe={loadStripe}
          createSubscriberWithStripeAccountApiUrl={createSubscriberWithStripeAccountApiUrl}
          updateSubscriberWithStripeAccountApiUrl={updateSubscriberWithStripeAccountApiUrl}
          stripePublishableKey={stripePublishableKey}
          userHasASubscription={isUserSubscribed}
        />
      )}
      <Gutter
        css={{
          backgroundColor: '$gs1',
          pb: '$12',
          bp1: {
            pb: '$16',
          },
          bp3: {
            pb: '$20',
          },
        }}>
        <CustomFlex
          css={{ maxWidth: '[750px]', margin: 'auto', mb: '$5', px: '$8' }}
          direction="column"
          justify="center"
          align="center">
          <WysiwygReader
            initialValue={description}
            css={{
              color: '$gs12',
              '&p[data-wisiwig-body1="true"]': {
                color: '$gs11',
              },
              textAlign: 'center',
              '& *:last-child': {
                paddingBottom: 0,
                margin: 0,
              },
            }}
          />
        </CustomFlex>
        <CustomGrid
          className={planGiftCardsContainer({
            columns:
              availableStripePlansOrderedByPriceId?.length === 1
                ? 1
                : availableStripePlansOrderedByPriceId?.length === 2
                  ? 2
                  : 3,
          })}
          columns={{
            base: '1',
            bp3:
              availableStripePlansOrderedByPriceId?.length === 1
                ? '1'
                : availableStripePlansOrderedByPriceId?.length === 2
                  ? '2'
                  : '3',
          }}
          gap={{ base: 'large', bp3: 'small' }}>
          {availableStripePlansOrderedByPriceId?.map((priceId, index) => {
            const plan = stripePlans.find(
              plan => plan.stripePriceIdMonthly === priceId || plan.stripePriceIdYearly === priceId,
            )
            if (!plan) return null
            let planType: StripePlanCard['planType'] = plan.stripePriceIdYearly === priceId ? 'yearly' : 'monthly'

            const userIsSubscribedToPlan =
              subscribedUserPlan?.system === 'STRIPE' && subscribedUserPlan.subscription?.productId === plan.productId

            const userIsSubscribedToPlanAndPrice = userIsSubscribedToPlan && subscribedPlanRecurrence === planType
            const isTopChoice =
              (index === 0 && availableStripePlansOrderedByPriceId.length === 2) ||
              (index === 1 && availableStripePlansOrderedByPriceId.length === 3)

            const promo =
              plan.promoCouponIdMonthly || plan.promoCouponIdYearly
                ? stripePromotions.find(
                    promo =>
                      promo.couponId === plan.promoCouponIdMonthly || promo.couponId === plan.promoCouponIdYearly,
                  )
                : undefined

            return (
              <StripePlanCard
                {...plan}
                priceForYearlyToggleChoice={plan.priceForYearlyToggleChoice || undefined}
                priceForMonthlyToggleChoice={plan.priceForMonthlyToggleChoice || undefined}
                isTopChoice={isTopChoice}
                key={plan.name}
                showYearlySavingsInCents={isSpecialCase1PlanWithBothOptions}
                name={isSpecialCase1PlanWithBothOptions ? undefined : plan.name}
                priceSubtitle={
                  (planType === 'monthly' ? plan.priceSubtitleMonthly : plan.priceSubtitleYearly) || undefined
                }
                clickSubscribeLinkOverride={
                  (planType === 'monthly'
                    ? plan.clickSubscribeLinkOverrideMonthly
                    : plan.clickSubscribeLinkOverrideYearly) || undefined
                }
                subtitle={isSpecialCase1PlanWithBothOptions ? undefined : plan.subtitle || undefined}
                planSummary={isSpecialCase1PlanWithBothOptions ? undefined : plan.planSummary || undefined}
                benefits={isSpecialCase1PlanWithBothOptions ? undefined : plan.benefits}
                status={status}
                loadStripe={loadStripe}
                stripePublishableKey={stripePublishableKey}
                userHasASubscription={isUserSubscribed}
                userIsSubscribedToPlanAndPrice={userIsSubscribedToPlanAndPrice}
                createSubscriberWithStripeAccountApiUrl={createSubscriberWithStripeAccountApiUrl}
                updateSubscriberWithStripeAccountApiUrl={updateSubscriberWithStripeAccountApiUrl}
                planType={planType}
                totalNumberOfPlans={availableStripePlansOrderedByPriceId.length as 1 | 2 | 3}
                promo={showPromo ? promo : undefined}
                css={{
                  order: isTopChoice ? -1 : index,
                  flex: '[1 0 100%]',
                  bp3: {
                    order: '[unset]',
                    flex: 'none',
                  },
                }}
              />
            )
          })}
        </CustomGrid>

        {isSpecialCase1PlanWithBothOptions && firstPlanInfo && (
          <CustomFlex
            gap="3"
            direction={'column'}
            css={{
              mt: '$10',
              width: 'fit-content',
              textAlign: 'center',
              marginLeft: '$auto',
              marginRight: '$auto',
            }}>
            {firstPlanInfo.planSummary && (
              <>
                <Text variant="body2">{firstPlanInfo.planSummary}</Text>
                <CustomDivider orientation="horizontal" size="small" css={{ color: '$gs6' }} />
              </>
            )}
            {firstPlanInfo.benefits && <WysiwygReader initialValue={firstPlanInfo.benefits} />}
          </CustomFlex>
        )}

        {isUserSubscribed && status === 'authenticated' && searchParams.get('action') === 'manage-subscription' && (
          <Text
            variant="caption"
            css={{ color: '$pri', margin: 'auto', width: 'fit', mt: '$10', cursor: 'pointer' }}
            onClick={() => setIsOpenCancelReasonModal(true)}>
            Want to cancel your subscription? Click Here.
          </Text>
        )}
        {!!disclaimer?.length && (
          <WysiwygReader
            initialValue={disclaimer}
            css={{
              mt: '$20',
              color: '$gs11',
              maxWidth: '700px',
              mx: '$auto',
              '&p[data-wisiwig-body1="true"]': {
                color: '$gs11',
                fontSize: '$caption',
                fontFamily: '$caption',
                lineHeight: '$caption',
              },
            }}
          />
        )}
        <CancelationReasonModal
          open={isCancelReasonModalOpen}
          setOpen={setIsOpenCancelReasonModal}
          setIsCancelSubscriptionModalOpen={setIsCancelSubscriptionModalOpen}
          setFeedbackFormReason={setFeedbackFormReason}
        />
        <CancelSubscriptionModal
          open={isCancelSubscriptionModalOpen}
          setOpen={setIsCancelSubscriptionModalOpen}
          setSubscriptionCancelledModalOpen={setSubscriptionCancelledModalOpen}
          subscriptionId={subscriptionId}
          cancelSubscriptionApiUrl={API_ROUTES_NEXT_PUBLIC_SITE.cancelStripeSubscriptionApiUrl}
          // discountPromotionalText={discountPromotionalText}
          // applyDiscountApiUrl={applyDiscountApiUrl}
          // feedbackFormReason={feedbackFormReason}
          // discountCode={discountCode}
          // discountApplicationSuccessText={discountApplicationSuccessText}
        />
        {isUserSubscribed && status === 'authenticated' && (
          <ConfirmSubscriptionCancelModal
            open={subscriptionCancelledModalOpen}
            setOpen={setSubscriptionCancelledModalOpen}
            subscribeToNewsletterApiUrl={subscribeToNewsletterApiUrl}
            setThankyouModalOpen={setThankyouModalOpen}
            newsletterId={newsletterId}
            subscribed={isUserSubscribedNewsLetter}
            userEmail={session?.user?.email}
          />
        )}

        <BlockModal
          openModal={thankyouModalOpen}
          onCloseModalCallback={setThankyouModalOpen}
          title="Thank You For Signing Up!">
          <Text variant="subtitle1" css={{ color: '$gs12', textAlign: 'center' }}>
            You have successfully signed up for the Newsletter.
          </Text>
        </BlockModal>
      </Gutter>
    </>
  )
}

const planGiftCardsContainer = cva({
  base: {
    mt: '$6',
    justifyItems: 'center',
    bp1: {
      mt: '$8',
    },
    bp3: {
      mt: '$10',
    },
  },
  variants: {
    columns: {
      1: {
        justifyItems: 'center',
      },
      2: {
        justifyItems: 'center',
        bp3: {
          width: 'fit-content',
          mx: '$auto',
        },
      },
      3: {
        justifyItems: 'unset',
      },
    },
  },
})
