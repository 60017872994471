'use client'

import { Button } from '@design-system/src/components/Button'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Textfield } from '@design-system/src/components/Textfield'
import { validEmail } from '@design-system/src/utils/validation-utils'
import { Dispatch, FC, SetStateAction, useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { BlockModal } from '../../BlockModal'

export type UserEmailFormInput = {
  email: string
}

export const GetUserEmail: FC<{
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  onSubmitCallback: (data: UserEmailFormInput) => void
  callInProgress: boolean
}> = ({ isOpen, setIsOpen, onSubmitCallback, callInProgress }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UserEmailFormInput>({
    mode: 'onSubmit',
    defaultValues: { email: '' },
  })

  const onSubmit = useCallback(
    async (data: UserEmailFormInput) => {
      try {
        await onSubmitCallback(data)
        setIsOpen(false)
      } catch (error) {
        console.error(error)
      }
    },
    [onSubmitCallback, setIsOpen],
  )

  return (
    <BlockModal openModal={isOpen} onCloseModalCallback={setIsOpen} title={'Please Enter Your Email Address'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomFlex direction="column" gap="3">
          <Controller
            name="email"
            control={control}
            rules={{
              required: 'Your Email is required.',
              pattern: {
                value: validEmail,
                message: 'Please enter a valid email address.',
              },
            }}
            render={({ field }) => (
              <Textfield {...field} fullWidth={true} reactFormErrors={errors} floatingLabel="* Gifter Email Address " />
            )}
          />
        </CustomFlex>

        <Button
          variant="primary"
          label="Purchase Gift"
          fullWidth={true}
          css={{ mt: '$6' }}
          buttonType="submit"
          buttonState={callInProgress ? 'waiting' : 'default'}
        />
      </form>
    </BlockModal>
  )
}
