export const postData = async ({ url, data = {} }: { url: string; data: unknown }): Promise<any> => {
  const res = await fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify(data),
  })

  if (res.status !== 200) {
    console.error('ERROR', res)
    throw new Error('error making request')
  }

  return res.json()
}

export const roundNumberToXDecimals = (num: number, decimals: number = 2): number => {
  const factor = Math.pow(10, decimals)
  return Math.round((num + Number.EPSILON) * factor) / factor
}
