import type { Stripe, StripeConstructorOptions } from '@stripe/stripe-js'

let stripePromise: Promise<Stripe | null>

export const getStripe = (
  accountId: string,
  stripePublishableKey: string,
  loadStripe: (publishableKey: string, options?: StripeConstructorOptions | undefined) => Promise<Stripe | null>,
): Promise<Stripe | null> => {
  // console.log('GET STRIPE')
  if (!stripePromise) {
    stripePromise = loadStripe(stripePublishableKey, {
      stripeAccount: accountId,
    })
  }
  return stripePromise
}
