import Link from 'next/link'
import React from 'react'
import { css } from 'styled-system/css'

import { LINK_ELEMENT_TYPES } from '@models/wysiwyg-types'

type WysiwygLinkProps = {
  linkType?: LINK_ELEMENT_TYPES
  data?: {
    email?: string
    openNewTab?: boolean
  }
  url?: string
  children?: React.ReactNode
  attributes: Record<string, unknown>
}

export const WysiwygLink: React.FC<WysiwygLinkProps> = props => {
  const { linkType, data, url, children, attributes } = props

  switch (linkType) {
    case 'external':
      const target = data?.openNewTab ? '_blank' : '_self'
      return (
        <a data-wisiwig-link {...attributes} href={url} target={target} rel="noreferrer" className={styledExternalLink}>
          {children}
        </a>
      )

    case 'internal':
      if (!url) {
        console.error('url is not defined', url, data, window.location.href)
        return null
      }
      return (
        <span {...attributes} className={styledInternalLink}>
          <Link prefetch={false} href={url} passHref rel="noopener noreferrer" data-wisiwig-link>
            {children}
          </Link>
        </span>
      )

    // case 'email':
    //   const href = `mailto:${data?.email}`
    //   return (
    //     <EditorLink url={href}>
    //       <a data-wisiwig-link {...attributes} href={href} target="_blank" rel="noreferrer">
    //         {children}
    //       </a>
    //     </EditorLink>
    //   )

    default:
      console.error('link type is not defined', linkType, data, url, window.location.href)
      return null
  }
}

const styledExternalLink = css({
  color: '$sec',
  cursor: 'pointer',
  transitionProperty: 'color',
  transitionDuration: '$fast',
  transitionTimingFunction: 'east-out',
  _hover: {
    color: '$sec_D',
    transitionDuration: '$fast',
    transitionTimingFunction: 'east-out',
    transitionProperty: 'color',
  },
})

const styledInternalLink = css({
  display: 'inline',
  '& a': {
    color: '$sec',
    cursor: 'pointer',
    transitionProperty: 'color',
    transitionDuration: '$fast',
    transitionTimingFunction: 'east-out',
    _hover: {
      color: '$sec_D',
      transitionDuration: '$fast',
      transitionTimingFunction: 'east-out',
      transitionProperty: 'color',
    },
  },
})
