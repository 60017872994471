'use client'

import { Gutter } from '@design-system/src/components/Gutter'
import { Text } from '@design-system/src/components/Text'
import { useRouter } from 'next-nprogress-bar'
import 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import { useEffect } from 'react'

export const ConnectedMessage = () => {
  const router = useRouter()

  useEffect(() => {
    // If user already subscribed, redirect to their account
    // Adding timeout so that the redirect UI doesn't flash.
    setTimeout(() => {
      router.push('/')
    }, 5000)
  }, [status, router])
  return (
    <Gutter css={{ paddingLeft: '$0 !important' }}>
      <Text variant="body1" css={{ color: '$gs12' }}>
        You are connected, we are redirecting you to the&nbsp;homepage.
      </Text>
    </Gutter>
  )
}
