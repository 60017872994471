import React from 'react'
import { css, cva, cx } from 'styled-system/css'
import type { SystemStyleObject } from 'styled-system/types'

const DEFAULT_SIZE = 'small'
const DEFAULT_PALETTE = 'color'

export interface CardProps {
  /**
   *  Decide the size of the card.
   */
  size?: 'small' | 'large'

  /**
   *  Decide the type of card.
   */
  palette?: 'color' | 'grayscale'

  /**
   *  Set css property.
   */
  css?: SystemStyleObject

  /**
   * Set className property.
   * @default undefined
   * */
  className?: string
}

export const LoadingIndicator: React.FC<CardProps> = ({
  size = DEFAULT_SIZE,
  palette = DEFAULT_PALETTE,
  css: cssProp = {},
  className,
}) => {
  return (
    <div className={cx(loadingIndicatorContainer({ size }), css(cssProp), className)}>
      <div className={loadingBarContainer({ palette })}>
        <div className={loadingBar({ palette })} />
      </div>
    </div>
  )
}

// https://codepen.io/stoepke/pen/QOOqGW
const loadingIndicatorContainer = cva({
  base: {
    display: 'flex',
  },
  variants: {
    size: {
      small: {
        height: '[4px]',
      },
      large: {
        height: '[10px]',
      },
    },
  },
  defaultVariants: {
    size: DEFAULT_SIZE,
  },
})

const loadingBarContainer = cva({
  base: {
    position: 'relative',
    width: '$full',
    padding: '$1',
  },
  variants: {
    palette: {
      color: {
        backgroundColor: '$sec_L',
      },
      grayscale: {
        backgroundColor: '$gs5',
      },
    },
  },
  defaultVariants: {
    palette: DEFAULT_PALETTE,
  },
})

const loadingBar = cva({
  base: {
    position: 'absolute',
    top: '0',
    right: '[100%]',
    bottom: '$0',
    left: '$0',
    width: '$0',
    animationName: 'borealisBar',
    animationDuration: '[2.5s]',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
  variants: {
    palette: {
      color: {
        backgroundColor: '$sec_D',
      },
      grayscale: {
        backgroundColor: '$gs8',
      },
    },
  },
  defaultVariants: {
    palette: DEFAULT_PALETTE,
  },
})
