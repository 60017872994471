'use client'

import { FC, useEffect, useMemo } from 'react'

export const InjectDangerousHtml: FC<{ html?: string; id: string }> = ({ html = '', id }) => {
  // Separate out <script> tags and clean the HTML content
  const { updatedHtml, scripts } = useMemo(() => {
    const scriptRegex = /<script\b([^>]*)>([\s\S]*?)<\/script>/gm
    const extractedScripts: { scriptAttributes: string; scriptContent: string }[] = []
    let cleanedHtml = html

    let match
    while ((match = scriptRegex.exec(html)) !== null) {
      const scriptAttributes = match[1].trim()
      const scriptContent = match[2].trim()
      extractedScripts.push({ scriptAttributes, scriptContent })
      cleanedHtml = cleanedHtml.replace(match[0], '') // Remove script tag from HTML
    }

    return { updatedHtml: cleanedHtml, scripts: extractedScripts }
  }, [html])

  useEffect(() => {
    // Inject each script into the DOM on client-side render
    scripts.forEach(({ scriptAttributes, scriptContent }) => {
      const script = document.createElement('script')

      if (scriptAttributes.includes('src=')) {
        // Extract the `src` attribute from the script tag
        const srcMatch = /src=["']([^"']+)["']/.exec(scriptAttributes)
        if (srcMatch) {
          script.src = srcMatch[1]
        }
      } else {
        // Inline script content
        script.text = scriptContent
      }

      // Add any other attributes
      scriptAttributes.split(/\s+/).forEach(attr => {
        const [key, value] = attr.split('=')
        if (key !== 'src' && key && value) {
          script.setAttribute(key, value.replace(/['"]/g, ''))
        }
      })

      document.body.appendChild(script)

      // Clean up
      return () => {
        document.body.removeChild(script)
      }
    })
  }, [scripts])

  return <div dangerouslySetInnerHTML={{ __html: updatedHtml }} id={id} />
}
