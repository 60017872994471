import { css, cx } from 'styled-system/css'
import { type CustomGridVariantProps, customGrid } from 'styled-system/recipes'
import type { SystemStyleObject } from 'styled-system/types'

type GridProps = CustomGridVariantProps & {
  children: React.ReactNode
  css?: SystemStyleObject
  className?: string
}
export const CustomGrid = (props: GridProps) => {
  const { children, className, css: cssProp = {}, columns, gap, ...rest } = props
  const rootClassName = cx(
    customGrid({
      columns,
      gap,
    }),
    css(cssProp),
    className,
  )
  return (
    <div {...rest} className={rootClassName}>
      {children}
    </div>
  )
}
