import { Button } from '@design-system/src/components/Button'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Text } from '@design-system/src/components/Text'
import { postData } from 'app/sites/[site]/[[...page]]/_utils/rest-utils'
import { FC, useState } from 'react'
import { API_ROUTES_NEXT_PUBLIC_SITE } from 'src/utils/route-utils'

import { BlockModal } from '../../../BlockModal'

type DeleteAccountModalProps = {
  modalIsOpen: boolean
  setIsDeleteAccountModalOpen: (value: boolean) => void
  setIsDeleteAccountSuccessModalOpen: (value: boolean) => void
  setShowAccountFailureErrorMsg: (value: boolean) => void
  showAccountFailureErrorMsg: boolean
}

export const DeleteAccountModal: FC<DeleteAccountModalProps> = props => {
  const [deleteAccountApiCallInProgress, setDeleteAccountApiCallInProgress] = useState(false)

  const onDeleteAccountClick = async () => {
    setDeleteAccountApiCallInProgress(true)
    try {
      const { error } = await postData<
        undefined,
        {
          error?: {
            message: string
          }
        }
      >({
        url: API_ROUTES_NEXT_PUBLIC_SITE.deleteSubscriberAccountsApiUrl,
        data: undefined,
      })
      if (error) {
        console.error('Error deleting account:', error)
        throw new Error('Failed to delete account ')
      }
      props.setIsDeleteAccountModalOpen(false)
      props.setIsDeleteAccountSuccessModalOpen(true)
    } catch (error) {
      props.setShowAccountFailureErrorMsg(true)
      console.error('error deleting account', error)
    } finally {
      setDeleteAccountApiCallInProgress(false)
    }
  }

  return (
    <BlockModal
      openModal={props.modalIsOpen}
      onCloseModalCallback={props.setIsDeleteAccountModalOpen}
      title="Account Deleted">
      <Text variant="subtitle1" css={{ color: '$gs12', textAlign: 'center', mb: '$7' }}>
        You are about to delete your account
      </Text>
      <Text variant="subtitle2" css={{ color: '$gs12', textAlign: 'center', mb: '$7' }}>
        This action is permanent and cannot be undone. Are you sure you wish to&nbsp;proceed?
      </Text>
      <Button
        label="Delete Account"
        fullWidth={true}
        onClick={onDeleteAccountClick}
        variant="primary"
        buttonState={deleteAccountApiCallInProgress ? 'waiting' : 'default'}
      />
      {props.showAccountFailureErrorMsg && (
        <CustomFlex justify="center" align="center" css={{ py: '$2', borderRadius: '$3' }}>
          <Text variant="caption" css={{ color: '$ale' }}>
            Sorry, an error occured while trying to delete your account. Please try again later or contact
            customer&nbsp;service.
          </Text>
        </CustomFlex>
      )}
    </BlockModal>
  )
}
