import { Box } from '@design-system/src/components/Box'
import { Icon } from '@design-system/src/components/Icon'
import { ShoImage } from '@design-system/src/components/ShoImage'
import { ImageIcon } from '@radix-ui/react-icons'
import { ImageProps } from 'next/image'
import { forwardRef } from 'react'
import { css } from 'styled-system/css'

type ImagePlaceholderProps = {
  src?: string
  width?: number
  height?: number
  css?: object
  isSquare?: boolean
  sizes?: ImageProps['sizes']
}

export const ImagePlaceholder = forwardRef<HTMLDivElement, ImagePlaceholderProps>(
  ({ src, width, height, css: cssProp = {}, isSquare, sizes }, forwardRef) => {
    return (
      <Box
        ref={forwardRef}
        css={{
          width: '$full',
          height: '$0',
          paddingBottom: isSquare ? '[100%]' : '[64%]',
          backgroundColor: '$gs6',
          position: 'relative',
          borderRadius: '$3',
          ...cssProp,
        }}>
        {src && width && height ? (
          <Box
            css={{
              position: 'absolute',
              inset: '$0',
              padding: '$4',
              opacity: 0.25,
            }}>
            <ShoImage
              alt="Placeholder Image"
              src={src}
              fill={true}
              doNotTransformURL={false}
              fillWrapperCss={css.raw({
                width: '$full',
                height: '$full',
              })}
              imageCss={css.raw({
                objectFit: 'contain',
              })}
              sizes={sizes}
            />
          </Box>
        ) : (
          <Icon
            reactIcon={<ImageIcon />}
            css={{
              position: 'absolute',
              top: '[50%]',
              left: '[50%]',
              transform: 'translate(-50%, -50%)',
              fontSize: '[100px !important]',
              color: '$gs8',
            }}
          />
        )}
      </Box>
    )
  },
)
ImagePlaceholder.displayName = 'ImagePlaceholder'
