import { Waiting, WaitingProps } from '@design-system/src/components/Waiting'
import pubgenLogo_dark from '@next-public-site/public/_static/pubgenLogo_dark.svg'
import { FC } from 'react'

export const WaitingWithData: FC<WaitingProps> = props => {
  const logoDetails: WaitingProps['logoDetails'] = {
    logoSrc: pubgenLogo_dark,
    logoWidth: 245,
    logoHeight: 54,
  }

  return <Waiting {...props} logoDetails={logoDetails} />
}
