import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Text } from '@design-system/src/components/Text'
import { Box } from 'styled-system/jsx'

export const AccountCard: React.FC<{
  children: React.ReactNode
  title: string
  headerCTA?: React.ReactNode
}> = ({ children, headerCTA, title }) => (
  <CustomFlex
    direction="column"
    justify={{ base: 'center', bp1: 'start' }}
    align={{ base: 'center', bp1: 'start' }}
    css={{
      backgroundColor: '$gs3',
      borderRadius: '$4',
      p: '$10',
    }}>
    <CustomFlex direction="row" wrap="wrap" align="baseline" justify={{ base: 'center', bp1: 'between' }}>
      <Text variant="h5" css={{ mr: '$2' }}>
        {title}
      </Text>
      {headerCTA && (
        <Box
          css={{
            display: 'none',
            bp1: {
              display: 'block',
            },
          }}>
          {headerCTA}
        </Box>
      )}
    </CustomFlex>
    {children}
    {headerCTA && (
      <CustomFlex
        justify="center"
        css={{
          mt: '$4',
          bp1: {
            display: 'none !important',
          },
        }}>
        {headerCTA}
      </CustomFlex>
    )}
  </CustomFlex>
)
