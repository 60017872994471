import { Button } from '@design-system/src/components/Button'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Text } from '@design-system/src/components/Text'
import { Textfield } from '@design-system/src/components/Textfield'
import { validEmail } from '@design-system/src/utils/validation-utils'
import { FC, useCallback } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { BlockModal } from '../../../BlockModal'

export type EmailAndAddressFormInput = {
  email: string
  firstName: string
  lastName: string
  address1: string
  address2: string
  city: string
  state: string
  zip: string
  country: string
}

export const EmailAndAddressModal: FC<{
  userInfoModalIsOpen: boolean
  setUserInfoModalIsOpen: (open: boolean) => void
  onSubmitUserInfo: SubmitHandler<EmailAndAddressFormInput>
  isUserUnauthenticated: boolean
  planRequiresShipping: boolean
  errorMsg?: string
  subscriptionInProgress?: boolean
}> = ({
  userInfoModalIsOpen,
  setUserInfoModalIsOpen,
  onSubmitUserInfo,
  isUserUnauthenticated,
  planRequiresShipping,
  errorMsg,
  subscriptionInProgress,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<EmailAndAddressFormInput>({ mode: 'onSubmit' })

  const handleSubmitUserInfoAndCloseModal = useCallback(
    async data => {
      await onSubmitUserInfo(data)
      setUserInfoModalIsOpen(false)
    },
    [onSubmitUserInfo],
  )

  return (
    <BlockModal
      openModal={userInfoModalIsOpen}
      onCloseModalCallback={setUserInfoModalIsOpen}
      title={
        isUserUnauthenticated && planRequiresShipping
          ? 'Please Enter Your Email and Shipping Address'
          : isUserUnauthenticated
            ? 'Please Enter Your Email Address'
            : 'Please Enter Your Shipping Address'
      }>
      <form onSubmit={handleSubmit(handleSubmitUserInfoAndCloseModal)}>
        <CustomFlex direction="column" gap="2">
          {isUserUnauthenticated && (
            <Controller
              name="email"
              control={control}
              rules={{
                required: 'Email is required.',
                pattern: {
                  value: validEmail,
                  message: 'Please enter a valid email address.',
                },
              }}
              render={({ field }) => (
                <Textfield {...field} fullWidth={true} reactFormErrors={errors} floatingLabel="* Enter Email Address" />
              )}
            />
          )}
          {planRequiresShipping && (
            <>
              <Controller
                name="firstName"
                control={control}
                rules={{
                  required: 'First Name is required.',
                }}
                render={({ field }) => (
                  <Textfield {...field} fullWidth={true} reactFormErrors={errors} floatingLabel="* First Name" />
                )}
              />
              <Controller
                name="lastName"
                control={control}
                rules={{
                  required: 'Last Name is required.',
                }}
                render={({ field }) => (
                  <Textfield {...field} fullWidth={true} reactFormErrors={errors} floatingLabel="* Last Name" />
                )}
              />
              <Controller
                name="address1"
                control={control}
                rules={{
                  required: 'Address is required.',
                }}
                render={({ field }) => (
                  <Textfield {...field} fullWidth={true} reactFormErrors={errors} floatingLabel="* Address" />
                )}
              />
              <Controller
                name="address2"
                control={control}
                render={({ field }) => (
                  <Textfield {...field} fullWidth={true} reactFormErrors={errors} floatingLabel="Address 2" />
                )}
              />
              <Controller
                name="city"
                control={control}
                rules={{
                  required: 'City is required.',
                }}
                render={({ field }) => (
                  <Textfield {...field} fullWidth={true} reactFormErrors={errors} floatingLabel="* City" />
                )}
              />
              <Controller
                name="state"
                control={control}
                rules={{
                  required: 'State is required.',
                }}
                render={({ field }) => (
                  <Textfield {...field} fullWidth={true} reactFormErrors={errors} floatingLabel="* State" />
                )}
              />
              <Controller
                name="zip"
                control={control}
                rules={{
                  required: 'Zip is required.',
                }}
                render={({ field }) => (
                  <Textfield {...field} fullWidth={true} reactFormErrors={errors} floatingLabel="* Zip" />
                )}
              />
              <Controller
                name="country"
                control={control}
                rules={{
                  required: 'Country is required.',
                }}
                render={({ field }) => (
                  <Textfield {...field} fullWidth={true} reactFormErrors={errors} floatingLabel="* Country" />
                )}
              />
            </>
          )}
        </CustomFlex>

        <Button
          variant="primary"
          label="Continue To Subscribe"
          fullWidth={true}
          css={{ mt: '$6' }}
          buttonType="submit"
          buttonState={subscriptionInProgress ? 'waiting' : 'default'}
        />
        {errorMsg && (
          <CustomFlex
            justify="center"
            align="center"
            css={{ p: '$2', borderRadius: '$3', backgroundColor: '$ale', mt: '$6' }}>
            <Text variant="caption" css={{ color: '$aleText' }}>
              {errorMsg}
            </Text>
          </CustomFlex>
        )}
      </form>
    </BlockModal>
  )
}
