import React from 'react'
import { css } from 'styled-system/css'

import { TypographyVariants } from '../../utils/typescript-utils'
import { SlateLeafType } from './types'

export const Leaf: React.FC<SlateLeafType> = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong style={{ fontWeight: 'bold' }}>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  if (leaf.fontSize) {
    const size = leaf.fontSize as TypographyVariants
    children = <span className={css({ txtStyle: size })}>{children}</span>
  }

  if (leaf.color) {
    children = <span style={{ color: leaf.color as string }}>{children}</span>
  }

  return <span {...attributes}>{children}</span>
}
