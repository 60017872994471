import { Button } from '@design-system/src/components/Button'
import { CustomDivider } from '@design-system/src/components/CustomDivider'
import { TrashIcon } from '@radix-ui/react-icons'
import { FC } from 'react'

import { BlockModal } from '../../../BlockModal'
import { FeedbackForm } from './FeedbackForm'

export const ManageAccountModal: FC<{
  modalIsOpen: boolean
  onCloseModalCallback: (value: boolean) => void
  children?: React.ReactNode
  onUserWishesToDeleteAccount: () => void
}> = props => {
  return (
    <BlockModal openModal={props.modalIsOpen} onCloseModalCallback={props.onCloseModalCallback} title="Manage Account">
      {props.children}
      {props.children && (
        <CustomDivider
          css={{
            my: '$5',
          }}
        />
      )}
      <FeedbackForm />
      <CustomDivider
        css={{
          my: '$5',
        }}
      />
      <Button
        css={{ mt: '$10' }}
        label="Delete Account"
        reactIcon={<TrashIcon />}
        variant="ghost"
        fullWidth={true}
        onClick={props.onUserWishesToDeleteAccount}
      />
    </BlockModal>
  )
}
