import { GoogleAdProps } from '../_components/GoogleAd'

export const isAdvertisementVisible = (googleAdProps: GoogleAdProps | undefined): boolean => {
  if (!googleAdProps) return false
  if (
    googleAdProps.adSystem === 'custom' &&
    googleAdProps.customAds &&
    googleAdProps.customAds.imgHeight &&
    googleAdProps.customAds.imgWidth &&
    googleAdProps.customAds.imgSrc
  ) {
    return true
  }

  const showGTM =
    googleAdProps.adSystem === 'googleAdManager' &&
    googleAdProps.gtmUnitCode &&
    googleAdProps.gtmSizes &&
    googleAdProps.gtmMinWidth &&
    googleAdProps.gtmMinHeight &&
    googleAdProps.gtmDivId

  if (showGTM) {
    return true
  }

  return false
}
