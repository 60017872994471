'use client'

import * as SeparatorPrimitive from '@radix-ui/react-separator'
import { FC } from 'react'
import { css, cx } from 'styled-system/css'
import { type CustomDividerVariantProps, customDivider } from 'styled-system/recipes'
import type { SystemStyleObject } from 'styled-system/types'

type DividerProps = CustomDividerVariantProps & {
  css?: SystemStyleObject
  className?: string
  onMouseDown?: (event: React.MouseEvent<HTMLSpanElement>) => void
  onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void
}

export const CustomDivider: FC<DividerProps> = props => {
  const { className, css: cssProp = {}, orientation, size, ...rest } = props
  const rootClassName = cx(
    customDivider({
      orientation,
      size,
    }),
    css(cssProp),
    className,
  )
  // SepearatorPrimitive is a client component from Radix. Need to make this file a client file as well
  return <SeparatorPrimitive.Root {...rest} className={rootClassName} />
}
