'use client'

import { Button } from '@design-system/src/components/Button'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Text } from '@design-system/src/components/Text'
import { useRouter, useSearchParams } from 'next/navigation'
import { FC, useState } from 'react'
import { PAGES_NEXT_PUBLIC_SITES } from 'src/utils/route-utils'
import { Divider } from 'styled-system/jsx'

import { BlockModal } from '../../BlockModal'

export const SuccessOrFailModal: FC<{ customerServiceNumber?: string }> = ({ customerServiceNumber }) => {
  const searchParams = useSearchParams()
  const state = searchParams.get('state')
  const [modalOpen, setModalOpen] = useState(state === 'success' || state === 'cancel')
  const router = useRouter()

  if (!state) return null

  const title = state === 'success' ? 'Your gift subscription is ready to be shared!' : 'Transaction Cancelled'
  const bodyText =
    state === 'success'
      ? 'An email has been sent to give you all the information you need to share your gift subscription.'
      : `An error occurred. Please try again later or contact customer support${customerServiceNumber ? `: ${customerServiceNumber}` : ''}.`

  const onCloseModalCallback = (isOpen: boolean) => {
    if (isOpen === false) {
      router.push(PAGES_NEXT_PUBLIC_SITES.gift)
    }
    setModalOpen(isOpen)
  }

  return (
    <BlockModal openModal={modalOpen} onCloseModalCallback={onCloseModalCallback} title={title}>
      <Text variant="subtitle1" css={{ color: '$gs12' }}>
        {bodyText}
      </Text>
      {state === 'success' && (
        <>
          <Divider css={{ my: '$6', color: '$gs8' }} />
          <CustomFlex justify={'between'}>
            <Button variant="primary" label="Purchase Other Gift" onClick={() => setModalOpen(false)} />
            <Button variant="secondary" label="Go to Home" routeOnClick={'/'} />
          </CustomFlex>
        </>
      )}
    </BlockModal>
  )
}
