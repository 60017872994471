import { Button } from '@design-system/src/components/Button'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Text } from '@design-system/src/components/Text'
import React, { useState } from 'react'

import { BlockModal } from '../../../BlockModal'

type ConfirmSubscriptionCancelModalProps = {
  open: boolean
  setOpen: (value: boolean) => void
  setThankyouModalOpen: (value: boolean) => void
  newsletterId?: string | null
  subscribed?: boolean
  subscribeToNewsletterApiUrl: string
  userEmail?: string
}

export const ConfirmSubscriptionCancelModal: React.FC<ConfirmSubscriptionCancelModalProps> = ({
  open,
  setOpen,
  setThankyouModalOpen,
  newsletterId,
  subscribed,
  subscribeToNewsletterApiUrl,
  userEmail,
}) => {
  const [apiCallInProgress, setApiCallInProgress] = useState<boolean>(false)
  const [failed, setFailed] = useState(false)

  const onSignupNewsLetter = async () => {
    setApiCallInProgress(true)
    const data = new URLSearchParams()
    data.append('email', userEmail || '')
    data.append('newsletterIds', newsletterId || '')

    try {
      await fetch(subscribeToNewsletterApiUrl, {
        method: 'post',
        body: data,
      })

      setApiCallInProgress(false)
      setOpen(false)
      setThankyouModalOpen(true)
    } catch (error) {
      setFailed(true)
    }
    setApiCallInProgress(false)
  }

  return (
    <BlockModal openModal={open} onCloseModalCallback={setOpen} title="Subscription Cancelled">
      <Text variant="body2" css={{ color: '$gs12', mb: '$10', textAlign: 'center' }}>
        Your subscription has been&nbsp;cancelled, but you can continue enjoying all our features until the end of your
        current billing period.
        <br /> If you change your mind, you're always welcome to re-subscribe at any time!
      </Text>
      {!subscribed && newsletterId && (
        <Button
          variant="primary"
          fullWidth={true}
          buttonState={apiCallInProgress ? 'waiting' : 'default'}
          label="Sign Up For Our Newsletter. It's Free!"
          css={{ borderRadius: '$3', my: '$10' }}
          onClick={onSignupNewsLetter}
        />
      )}

      {failed && (
        <CustomFlex
          justify="center"
          align="center"
          css={{ py: '$2', borderRadius: '$3', backgroundColor: '$ale', mt: '$6' }}>
          <Text variant="caption" css={{ color: '$aleText' }}>
            An error occurred. Please try again.
          </Text>
        </CustomFlex>
      )}
    </BlockModal>
  )
}
