'use client'

import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Gutter } from '@design-system/src/components/Gutter'
import { signIn, useSession } from 'next-auth/react'
import 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import { useSearchParams } from 'next/navigation'
import React, { useEffect, useState } from 'react'
import { css } from 'styled-system/css'

import { BlockPubSigninCustomPageData, BlockViewProps, MagicLinkStateType } from '../../../_utils/typescript-utils'
import { ConnectedMessage } from './ConnectedMessage'
import { CustomerSerivce } from './CustomerService'
import { Greeting } from './Greeting'
import { MagicLinkSentMessage } from './MagicLinkSentMessage'
import { SignInCode } from './SignInCode'
import { SignInEmail } from './SignInEmail'

export type LoginType = 'magic-link' | 'code'

export const BlockPubSignin: React.FC<
  BlockViewProps<{ ShapeOfCustomPropsDerivedFromPageData: BlockPubSigninCustomPageData }>
> = props => {
  const [magicLinkState, setMagicLinkState] = useState<MagicLinkStateType>(props.blockCustomData.initialMagicLinkState)
  const [submittedEmail, setSubmittedEmail] = useState<string | null>(null)
  const search = useSearchParams()
  const [loginType, setLoginType] = useState<LoginType>(search.get('type') === 'code' ? 'code' : 'magic-link')

  const { customerServiceNumber, customerServiceNumberURI } = props.blockCustomData
  const { status } = useSession()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams.get('email-sent')) {
      setMagicLinkState('sent')
    }
  }, [])

  return (
    <Gutter
      css={{ bgColor: '$gs3' }}
      variant="bare"
      data-sho-block-name="BlockPubSignin"
      data-sho-block-id={props.blockId}>
      <CustomFlex direction={{ base: 'column', bp3: 'row' }} justify="center" css={signinWrapper}>
        <Gutter>
          <div className={siginContainer}>
            <CustomFlex direction="column" justify="center" align="center" css={{ height: '$full' }}>
              <Greeting magicLinkState={magicLinkState} />
              {status === 'authenticated' ? (
                <ConnectedMessage />
              ) : magicLinkState === 'sent' ? (
                loginType === 'code' && submittedEmail ? (
                  <SignInCode submittedEmail={submittedEmail} customerServiceNumber={customerServiceNumber} />
                ) : (
                  <MagicLinkSentMessage
                    submittedEmail={submittedEmail}
                    customerServiceNumber={customerServiceNumber}
                    setLoginType={setLoginType}
                  />
                )
              ) : (
                <SignInEmail
                  magicLinkState={magicLinkState}
                  setMagicLinkState={setMagicLinkState}
                  setSubmittedEmail={setSubmittedEmail}
                />
              )}
            </CustomFlex>
            <CustomerSerivce
              customerServiceNumber={customerServiceNumber}
              customerServiceNumberURI={customerServiceNumberURI}
            />
          </div>
        </Gutter>
      </CustomFlex>
    </Gutter>
  )
}

export const signInUser = async ({ email, type }: { email: string; type: 'code' | 'email' }) => {
  const url = new URL(location.href)
  url.pathname = '/'
  const provider = type === 'code' ? 'email-code' : 'email'
  return await signIn(provider, {
    email,
    callbackUrl: url.toString(),
    redirect: false,
  })
}

const signinWrapper = {
  bgColor: '$gs1',
  position: 'relative',
  justifyContent: 'center',
  bp3: {
    minHeight: '[80vh]',
  },
} as const

const siginContainer = css({
  color: '$gs2',
  flexGrow: 2,
  maxWidth: '[500px]',
  margin: 'auto',
  height: '$full',
  textAlign: 'center',
  '& form': {
    width: '$full',
  },
})
