import { Box } from '@design-system/src/components/Box'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Icon } from '@design-system/src/components/Icon'
import { Text } from '@design-system/src/components/Text'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { Cross1Icon } from '@radix-ui/react-icons'
import React, { useEffect, useState } from 'react'
import { css } from 'styled-system/css'

export interface ModalComponentProps {
  /**
   * The header title of the modal
   */
  title?: string

  /**
   * Show modal component
   */
  openModal: boolean

  /**
   * Called when click the close button or outside of the modal content.
   */
  onCloseModalCallback: (open: boolean) => void

  /**
   * set Css
   */
  css?: object

  /**
   * Show children component
   */
  children: React.ReactNode
}

export const BlockModal: React.FC<ModalComponentProps> = ({ children, ...props }) => {
  const { css: cssProp = {}, title = '', onCloseModalCallback, openModal } = props
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    setOpen(openModal)
  }, [openModal])

  const onCloseModalClick = () => {
    setOpen(false)
    onCloseModalCallback(false)
  }

  return (
    <DialogPrimitive.Root open={open} onOpenChange={onCloseModalClick}>
      <DialogPrimitive.Portal>
        <DialogPrimitive.Overlay className={styledOverlay}>
          <DialogPrimitive.Content className={css(modalContent, cssProp)}>
            <CustomFlex css={headerCss} justify={'between'} align={'center'} gap="3">
              <Text css={{ color: '$gs12', textAlign: 'center', lineHeight: '[1em]', textWrap: 'pretty' }} variant="h5">
                {title}
              </Text>
              <span>
                <Icon reactIcon={<Cross1Icon />} css={closeIconCss} onClick={onCloseModalClick} size={'12'} />
              </span>
            </CustomFlex>
            <Box css={{ overflowY: 'auto' }}>{children}</Box>
          </DialogPrimitive.Content>
        </DialogPrimitive.Overlay>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  )
}

const headerCss = css.raw({
  color: '$gs12',
  pb: '$2',
  mb: '$4',
  backgroundColor: '$gs1',
  borderBottomStyle: 'solid',
  borderBottomWidth: '$1',
  borderBottomColor: '$gs8',
})

const closeIconCss = css.raw({
  marginLeft: 'auto',
  cursor: 'pointer',
  color: '$gs10',
  borderWidth: '$1',
  borderStyle: 'solid',
  borderColor: '$gs6',
  borderRadius: '$3',
  padding: '[2px]',
  backgroundColor: '$gs3',
  '&:hover': {
    backgroundColor: '$gs5',
    transitionProperty: 'all',
    transitionDuration: '$fast',
  },
})

const styledOverlay = css({
  backgroundColor: '$b5',
  position: 'fixed',
  inset: '$0',
  zIndex: '[2000]',
})

const modalContent = css.raw({
  backgroundColor: '$gs1',
  borderRadius: '[15px]',
  position: 'fixed',
  top: '[50%]',
  left: '[50%]',
  transform: '[translate(-50%, -50%)]',
  maxHeight: '[70vh]',
  width: '[max(70vw, 300px)]',
  display: 'flex',
  flexFlow: 'column nowrap',
  zIndex: '[100]',
  padding: '$6',
  _focus: { outline: 'none' },
  bp2: { padding: '$8' },
  bp3: { padding: '$10' },
  bp1: {
    width: '[min(50vw, 600px)]',
  },
})
