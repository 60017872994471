import { Box } from '@design-system/src/components/Box'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Icon } from '@design-system/src/components/Icon'
import { ShoImage } from '@design-system/src/components/ShoImage'
import { Text } from '@design-system/src/components/Text'
import { truncateText } from '@design-system/src/utils/string-utils'
import { VideoIcon } from '@radix-ui/react-icons'
import { AuthorsPreviewType } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import { ImageProps } from 'next/image'
import Link from 'next/link'
import React, { useMemo } from 'react'
import { roundNumberToXDecimals } from 'src/utils/helpers'
import { css, cva } from 'styled-system/css'

import { AuthorsPreview } from './AuthorsPreview'
import { ImagePlaceholder } from './ImagePlaceholder'
import { SectionAndDatePreview } from './SectionAndDatePreview'

type IMG_ASPECT_RATIO_TYPE = 'square' | 'sixteen_by_nine'

export type ArticlePreviewHorizontalType = {
  /**
   * Name of the section.
   */
  sectionName?: string | null

  /**
   * Href of the section.
   */
  sectionHref?: string | null

  /**
   * Image source.
   */
  src?: string | null

  /**
   * Image sizes.
   */
  sizes?: ImageProps['sizes']

  priority?: ImageProps['priority']

  /**
   * placeholderSizes sizes.
   */
  placeholderSizes?: ImageProps['sizes']

  /**
   * base64 placeholder image that will be used as blury placeholder while image is loading
   */
  blurryPlaceholder?: string | null
  /**
   * Date of article release.
   */
  date: string

  /**
   * Article title.
   */
  title: string

  /**
   * Article summary.
   */
  summary: string

  /**
   * Href of the article.
   */
  articleHref: string

  /**
   * List of authors to display.
   */
  authors: AuthorsPreviewType
  /**
   * Is an article with a Iframe video.
   */
  hasVideo?: boolean

  /**
   * CSS.
   */
  css?: object

  /**
   * Image height.
   */
  imageHeight?: number | null

  /**
   * Image width.
   */
  imageWidth?: number | null

  /**
   * Image caption
   * */
  imageCaption?: string | null

  /**
   * Image credits
   * */
  imageCredits?: string | null

  /**
   * Does not transform the URL if on sho-app.
   * This props is used by ShoImage component.
   * Defaults to false
   */
  doNotTransformImageURL?: boolean

  /**
   * Says if the section should be wrapped with a link or should simply be text.
   * Defaults to true.
   */
  wrapSectionWithLink?: boolean

  /**
   * If set to true, the authors will be hidden.
   * Defaults to false.
   */
  hideAuthors?: boolean

  /**
   * Image placeholder in case there is no image passed
   */
  imgPlaceholder?: string

  /**
   * Image placeholder width.
   */
  imgPlaceholderWidth?: number

  /**
   * Image placeholder height.
   */
  imgPlaceholderHeight?: number

  /**
   * If true, the text will be on the left and image on the right
   */
  asHero?: boolean

  /*
  * If true, the image will be squared
    defaults to false 
  */
  imgBoundInAspectRatio?: boolean
}

const imgBoundInAspectRatioDefault: ArticlePreviewHorizontalType['imgBoundInAspectRatio'] = false
const asHeroDefault = false

export const ArticlePreviewHorizontal: React.FC<ArticlePreviewHorizontalType> = props => {
  const {
    sectionName,
    sectionHref,
    src,
    title,
    articleHref,
    authors,
    summary,
    hasVideo = false,
    date,
    imageHeight,
    imageWidth,
    css: cssProp = {},
    doNotTransformImageURL = false,
    wrapSectionWithLink = true,
    hideAuthors = false,
    imgPlaceholder,
    imgPlaceholderWidth,
    imgPlaceholderHeight,
    imageCaption,
    imageCredits,
    blurryPlaceholder,
    asHero = asHeroDefault, // only used for BlockPubSectionTopStories and BlockPubLeadArticle
    imgBoundInAspectRatio = imgBoundInAspectRatioDefault, // only used fro BlockPubHomeTopStories
    sizes,
    placeholderSizes = '(max-width: 77.5em) 100vw, (max-width: 87.5em) 75vw, 800px',
    priority = false,
  } = props

  const imageSizes = useMemo(() => {
    if (sizes) return sizes
    if (asHero) {
      return '(min-width: 77.5em) 736px, (min-width: 37.5em) calc(100vw - 64px), calc(100vw - 48px)'
    } else {
      return '(min-width: 87.5em) 800px, (min-width: 77.5em) 75vw, 100vw'
    }
  }, [sizes, asHero])

  if (!articleHref || !sectionHref) {
    console.log('ERROR TRYING TO RENDER ARTICLE PREVIEW VERTICAL LARGE, PROPS:', props)
    return null
  }

  return (
    <CustomFlex
      direction={{ base: 'column', bp3: 'row' }}
      css={cssProp}
      justify={{ base: 'start', bp3: 'between' }}
      gap={{ base: '4', bp3: '8' }}
      align={asHero ? 'center' : 'start'}>
      <CustomFlex
        justify="center"
        align="center"
        className={imageColumnWrapper({
          asHero,
        })}>
        <div className={previewImageWrapper({ imgBoundInAspectRatio })}>
          <Link href={articleHref} className={css({ width: '$full', overflow: 'hidden', borderRadius: '$3' })}>
            {src && imageHeight && imageWidth ? (
              <>
                {imgBoundInAspectRatio ? (
                  <ShoImage
                    className={shoImageStyled({
                      asHero,
                      imgBoundInAspectRatio,
                      imgIsContained: imageHeight > imageWidth,
                      aspectRatioSize:
                        roundNumberToXDecimals(imageWidth / imageHeight) >= roundNumberToXDecimals(16 / 9)
                          ? 'sixteen_by_nine'
                          : 'square',
                    })}
                    blurryPlaceholder={blurryPlaceholder}
                    doNotTransformURL={doNotTransformImageURL}
                    fill={true}
                    src={src}
                    alt={title}
                    sizes={imageSizes}
                    // priority={priority}
                  />
                ) : (
                  <div style={{ overflow: 'hidden' }}>
                    <ShoImage
                      className={shoImageStyled({
                        asHero,
                        imgBoundInAspectRatio,
                        imgIsContained: imageHeight > imageWidth,
                      })}
                      blurryPlaceholder={blurryPlaceholder}
                      doNotTransformURL={doNotTransformImageURL}
                      src={src}
                      alt={title}
                      width={imageWidth}
                      height={imageHeight}
                      sizes={imageSizes}
                      // fillWrapperCss={{ borderRadius: '$3', display: 'none !important' }}
                      // imageCss={{ borderRadius: '$3' }}
                      // priority={priority}
                    />
                  </div>
                )}

                {imageCaption || imageCredits ? (
                  <CustomFlex
                    direction="column"
                    gap={'1'}
                    css={{
                      textAlign: 'left',
                      backgroundColor: '$gs1',
                      color: '$gs11',
                      pt: '$2',
                    }}>
                    {imageCaption && (
                      <Text variant="caption" oll={true}>
                        {imageCaption}
                      </Text>
                    )}
                    {imageCredits && (
                      <Text variant="caption" oll={true}>
                        {imageCredits}
                      </Text>
                    )}
                  </CustomFlex>
                ) : null}
              </>
            ) : (
              <ImagePlaceholder
                src={imgPlaceholder}
                width={imgPlaceholderWidth}
                height={imgPlaceholderHeight}
                isSquare={imgBoundInAspectRatio}
                sizes={placeholderSizes}
              />
            )}
            {hasVideo && (
              <Icon
                reactIcon={<VideoIcon />}
                css={{
                  color: '$gs12',
                  backgroundColor: '$w2',
                  padding: '$5',
                  borderRadius: '[50%]',
                  opacity: 0.7,
                  position: 'absolute',
                  cursor: 'pointer',
                  right: '[16px]',
                  bottom: '[16px]',
                }}
              />
            )}
          </Link>
        </div>
      </CustomFlex>
      <CustomFlex direction="column" className={previewDescriptionWrapper({ flipped: asHero })}>
        {sectionName && (
          <SectionAndDatePreview
            sectionName={sectionName}
            sectionHref={sectionHref}
            date={date}
            wrapSectionWithLink={wrapSectionWithLink}
            css={{ mb: '$3', wordBreak: 'break-word' }}
            colorVariant="color"
          />
        )}
        <Link href={articleHref} className={articleLink}>
          {title && (
            <Text variant="h4" css={{ color: '$gs12', textAlign: 'left', wordBreak: 'break-word' }}>
              {asHero ? title : truncateText(title, 100)}
            </Text>
          )}
          {summary && (
            <Text variant="body2" oll={false} css={{ color: '$gs11', mt: '$6', wordBreak: 'break-word' }}>
              {truncateText(summary, 265)}
            </Text>
          )}
        </Link>
        {!!(authors?.authors?.length && !hideAuthors) && (
          <Box
            css={{
              mb: '$4',
              bp3: {
                mb: '$6',
              },
            }}
          />
        )}
        {authors?.authors && !hideAuthors && (
          <AuthorsPreview {...authors} doNotTransformImageURL={doNotTransformImageURL} size="small" />
        )}
      </CustomFlex>
    </CustomFlex>
  )
}

const previewDescriptionWrapper = cva({
  base: {
    px: '$0',
    maxWidth: '[75rem]',
    bp3: {
      width: '[51%]',
    },
  },
  variants: {
    flipped: {
      true: {
        bp3: {
          order: '-1',
        },
        order: '1',
      },
      false: {
        order: '1',
      },
    },
  },
  defaultVariants: {
    flipped: false,
  },
})

const shoImageStyled = cva({
  base: {
    display: 'block',
    width: '$full',
    height: 'auto',
    maxWidth: '$full',
    objectFit: 'cover',
    transitionProperty: 'all',
    transitionDuration: '$slow',
    transitionTimingFunction: 'out',
    borderRadius: '$3',
    backgroundColor: '$gs7',
    _hover: {
      transitionProperty: 'all',
      transitionDuration: '$slow',
      transitionTimingFunction: 'in',
      transform: 'scale(1.03)',
    },
  },
  variants: {
    asHero: {
      true: {
        maxHeight: '[50vh]',
        objectPosition: 'top center',
      },
      false: {
        maxHeight: '[min(322px, 50vh)]',
      },
    },
    imgBoundInAspectRatio: {
      true: {
        width: '$full',
        height: '$0',
        borderRadius: '$3',
        '& img': {
          maxHeight: '[max(50vh, 300px)]',
        },
      },
    },
    aspectRatioSize: {
      square: {
        paddingBottom: '[100%]',
      },
      sixteen_by_nine: {
        paddingBottom: '[56.25%]',
      },
    },
    imgIsContained: {
      true: {
        objectFit: 'contain',
        '& img': {
          objectFit: 'contain',
        },
      },
      false: {
        objectFit: 'cover',
        '& img': {
          objectFit: 'cover',
        },
      },
    },
  },
  defaultVariants: {
    asHero: asHeroDefault,
    imgBoundInAspectRatio: imgBoundInAspectRatioDefault,
    imgIsContained: false,
  },
})

const imageColumnWrapper = cva({
  base: {
    width: '$full',
    flexGrow: 1,
    bp3: { borderRadius: '$3' },
  },
  variants: {
    asHero: {
      true: {
        bp3: { flex: '[1 0 calc(69% - 36px)]' },
      },
      false: {
        bp3: { flex: '[1 0 43%]' },
      },
    },
  },
  defaultVariants: {
    asHero: asHeroDefault,
  },
})

const previewImageWrapper = cva({
  base: {
    boxSizing: 'border-box',
    position: 'relative',
    borderRadius: '$3',
    overflow: 'hidden',
    width: '$full',
    bp3: {
      mb: '$0',
    },
    '& div': {
      // can't target div wrapping the image otherwise
      borderRadius: '$3',
    },
  },
  variants: {
    imgBoundInAspectRatio: {
      true: {
        maxHeight: '[max(50vh, 300px)]',
      },
    },
  },
  defaultVariants: {
    imgBoundInAspectRatio: imgBoundInAspectRatioDefault,
  },
})

const articleLink = css({
  textWrap: '[pretty]',
  _hover: {
    '& > span:first-child': {
      transitionProperty: 'opacity',
      transitionDuration: '$normal',
      transitionTimingFunction: 'in-out',
      opacity: 0.7,
    },
  },
})
