import { FC } from 'react'
import { css } from 'styled-system/css'

export const CustomSubmitBtn: FC<{
  disabled: boolean
  label: string
}> = ({ disabled = false, label }) => {
  return (
    <button className={css({ txtStyle: 'body1' }, customBtn)} type="submit" disabled={disabled}>
      {label}
    </button>
  )
}

const customBtn = {
  color: '$pri',
  backgroundColor: 'transparent',
  outline: 'none',
  cursor: 'pointer',
  border: 'none',
  _hover: {
    opacity: 0.6,
    transitionProperty: 'opacity',
    transitionDuration: '$normal',
    transitionTimingFunction: 'in-out',
  },
  _focus: {
    outlineWidth: '$1',
    outlineStyle: '[solid]',
    outlineColor: '$pri',
  },
  _disabled: {
    cursor: '[default]',
    opacity: 0.6,
  },
} as const
