'use client'

import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { ChevronDownIcon } from '@radix-ui/react-icons'
import React from 'react'
import { css, cva, cx } from 'styled-system/css'
import type { SystemStyleObject } from 'styled-system/types'

import { WysiwygElement } from '@models/wysiwyg-types'

import { Icon } from './Icon'
import { Text } from './Text'
import { WysiwygReader } from './WysiwygReader'

type AccordionItem = {
  id: string
  panelHeader: string
  panelBody: WysiwygElement[]
}
export interface AccordionProps {
  /**
   * set default value with the panel id .
   * string value is for single type, string array is for the multiple type.
   * e.g the type is 'single', defaultPanel can be set as '2'
   * If the type is 'multiple, defaultPanel can be set as ['1', '2']
   * */
  defaultPanel?: string | string[]

  /**
   * The accordion list to show.
   */
  panels?: AccordionItem[]

  /**
   * Give css property to accordion component.
   */
  css?: SystemStyleObject

  /**
   * The max width of the accordion.
   */
  maxWidth?: number

  /**
   * To open single or multiple items at once. Defaults to 'single'
   */
  type?: 'single' | 'multiple'

  /**
   * Return the opened accordion.
   */
  onOpenAccordion?: (value: string[] | string) => void

  /**
   * To set colored or neutral accordian. Default to 'neutral'
   */
  variant?: 'neutral' | 'color'
  className?: string
}

interface ListItemProps {
  variant: AccordionProps['variant']
  panels: AccordionProps['panels']
}

export const Accordion: React.FC<AccordionProps> = ({
  panels = [],
  maxWidth = '800px',
  css: cssProp = {},
  type = 'single',
  defaultPanel = type == 'single' ? '' : [''],
  onOpenAccordion = () => {},
  variant = 'neutral',
  className,
}) => {
  return type === 'single' ? (
    <AccordionPrimitive.Root
      type="single"
      onValueChange={(value: string[] | string) => onOpenAccordion(value)}
      collapsible={true}
      defaultValue={defaultPanel as string}
      style={{ maxWidth }}
      className={cx(css(accordionMain, cssProp), className)}>
      <ListItem panels={panels} variant={variant} />
    </AccordionPrimitive.Root>
  ) : (
    <AccordionPrimitive.Root
      type="multiple"
      style={{ maxWidth }}
      onValueChange={(value: string[] | string) => onOpenAccordion(value)}
      defaultValue={defaultPanel as string[]}
      className={css(accordionMain, cssProp)}>
      <ListItem panels={panels} variant={variant} />
    </AccordionPrimitive.Root>
  )
}

const ListItem: React.FC<ListItemProps> = ({ panels = [], variant = 'neutral' }: ListItemProps) => {
  return (
    <>
      {panels?.map((panel: AccordionItem, index) => {
        const initialValue = typeof panel.panelBody === 'string' ? JSON.parse(panel.panelBody) : panel.panelBody
        const isLastItem = index === panels.length - 1
        return (
          <AccordionPrimitive.Item
            value={panel.id.toString()}
            key={panel.id}
            className={accordionSingleItem({ isLastItem })}>
            <AccordionPrimitive.Header>
              <AccordionPrimitive.Trigger
                id={`radix-1-${panel.id}`}
                className={styledTrigger({
                  variant,
                })}>
                <Text variant="body1">{panel.panelHeader}</Text>
                <Icon
                  css={css.raw({ mt: '$1', ml: '$2', color: variant === 'color' ? '$priText' : '[inherit]' })}
                  reactIcon={<ChevronDownIcon />}
                  size={'20'}
                />
              </AccordionPrimitive.Trigger>
            </AccordionPrimitive.Header>
            <AccordionPrimitive.Content className={styledContent}>
              <WysiwygReader initialValue={initialValue} />
            </AccordionPrimitive.Content>
          </AccordionPrimitive.Item>
        )
      })}
    </>
  )
}

const accordionMain = css.raw({
  position: 'relative',
  background: '$none',
  width: '$full',
  margin: 'auto',
})

const styledTrigger = cva({
  base: {
    position: 'relative',
    display: 'flex',
    cursor: 'pointer',
    border: 'none',
    outline: '[0]',
    width: '$full',
    px: '$6',
    py: '$4',
    boxSizing: 'border-box',
    borderRadius: '$3',
    justifyContent: 'space-between',
    textAlign: 'left',
    _after: {
      content: '" "',
      position: 'absolute',
      display: 'block !important',
      inset: '$0',
    },
    '&[disabled]': {
      cursor: 'default',
      background: '$gs1',
      color: '$gs8',
      borderWidth: '$1',
      borderStyle: 'solid',
      borderColor: '$gs8',
    },
    '&:not([disabled])': {},
    '&[data-state="open"]:not([disabled])': {
      borderTopLeftRadius: '$3',
      borderTopRightRadius: '$3',
    },
    '&[data-state="closed"]:after': {},
    '&[data-state="open"]:before': {
      borderTopLeftRadius: '$3!',
      borderTopRightRadius: '$3!',
      borderBottomLeftRadius: '$0!',
      borderBottomRightRadius: '$0!',
    },
    '&[data-state="open"] > span:nth-child(2)': {
      transform: 'rotate(180deg)',
    },
    '& > span:nth-child(2)': {
      transitionProperty: '[all]',
      transitionDuration: '$normal',
      transitionTimingFunction: '[ease]',
    },
  },
  variants: {
    variant: {
      neutral: {
        backgroundColor: '$gs3',
        color: '$gs12',
        '&:not([disabled]):hover': {
          backgroundColor: '$gs4',
        },
      },
      color: {
        backgroundColor: '$pri',
        color: '$priText',
        '&:not([disabled]):hover': {
          backgroundColor: '$pri_L',
        },
      },
    },
  },

  defaultVariants: {
    variant: 'neutral',
  },
})

const accordionSingleItem = cva({
  base: {
    position: 'relative',
    boxSizing: 'border-box',
    background: '$gs5',
    borderRadius: '$3',
  },
  variants: {
    isLastItem: {
      true: {},
      false: {
        mb: '$4',
      },
    },
  },
  defaultVariants: {
    isLastItem: false,
  },
})

const styledContent = css({
  overflow: 'hidden',
  transitionProperty: 'max-height',
  transitionDuration: '$normal',
  transitionTimingFunction: '[cubic-bezier(0.87, 0, 0.13, 1)]',
  textAlign: 'left',
  display: 'flex',
  alignItems: 'flex-end',
  px: '$6',
  color: '$gs12',
  '&[data-state="open"]': {
    maxHeight: '$full',
    py: '$4',
    transitionProperty: 'all',
    transitionDuration: '$normal',
    transitionTimingFunction: '[cubic-bezier(0.87, 0, 0.13, 1)]',
  },
  '&[data-state="closed"]': {
    maxHeight: '0',
    py: '$0',
  },
})
