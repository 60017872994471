'use client'

import { CustomFlex } from '@design-system/src/components/CustomFlex'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css'
import dynamic from 'next/dynamic'
import { FC, useCallback } from 'react'
import 'react-calendar/dist/Calendar.css'
import { css } from 'styled-system/css'

import { Action, State, Value } from '..'

const DateRangePicker = dynamic(() => import('@wojtekmaj/react-daterange-picker'))

export const DateRangeSelection: FC<{
  state: State
  dispatch: React.Dispatch<Action>
}> = ({ state, dispatch }) => {
  const onDateRangeChange = useCallback(
    (value: Value) => {
      dispatch({ type: 'setDatePickerValues', payload: value })
    },
    [dispatch],
  )

  return (
    <CustomFlex
      css={{
        px: '$6',
        py: '$3',
        bgColor: '$gs3',
        borderLeftStyle: 'solid',
        borderLeftColor: '$gs12',
        bp1: {
          borderLeftWidth: '$1',
          borderTopWidth: '0',
        },
      }}
      direction={'column'}
      gap={'3'}>
      <div className={datePickerStyles}>
        <DateRangePicker
          onChange={onDateRangeChange}
          value={state.datePickerValues}
          isOpen={true}
          maxDate={new Date()}
          shouldCloseCalendar={() => false}
        />
      </div>
    </CustomFlex>
  )
}

const datePickerStyles = css({
  fontSize: '$body2',
  lineHeight: '1em',
  fontFamily: '$body2',
  cursor: 'pointer',
  width: '100%',
  background: '$none',
  color: '$gs12',

  '& .react-daterange-picker__calendar': {
    maxWidth: '80vw !important',
    position: 'static !important',
    height: 'fit-content !important',
  },
  '& .react-daterange-picker__wrapper, & .react-daterange-picker__calendar .react-calendar': {
    borderColor: '$gs9 !important',
  },
  '& .react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from': {
    fontWeight: 'bold',
  },
  '& .react-calendar__viewContainer': {
    fontFamily: '$body2 !important',
  },
  '& .react-calendar__viewContainer .react-calendar__tile': {
    color: '$gs11',
    bgColor: '$gs1',
  },
  '& .react-daterange-picker': {
    display: 'flex',
    flexDirection: 'column',
    gap: '$3',
  },
  '& .react-daterange-picker__calendar-button.react-daterange-picker__button': {
    display: 'none',
  },
  '& .react-calendar__month-view__days__day--weekend, & .react-calendar__month-view__days__day': {
    color: '$gs11 !important',
  },
  '& .react-calendar__tile': {
    py: '$2 !important',
    px: '$1 !important',
  },
  '& .react-calendar__month-view__weekdays': {
    cursor: 'default !important',
  },
  '& .react-calendar__tile--hasActive': {
    color: '$secText !important',
    bgColor: '$sec !important',
  },
  '& .react-calendar__tile--now': {
    color: '$gs11 !important',
    bgColor: '$gs1 !important',
  },
  '& .react-calendar__tile:disabled, & .react-calendar__navigation button:disabled': {
    color: '$gs6 !important',
    bgColor: '$gs3 !important',
  },

  '& .react-calendar__navigation button:enabled:focus': {
    bgColor: '$gs1 !important',
  },
  '& .react-calendar__navigation button:enabled:hover': {
    bgColor: '$gs7 !important',
  },
  '& .react-calendar__tile:enabled:hover:not(.react-calendar__tile--active)': {
    bgColor: '$gs5 !important',
  },
  '& .react-calendar__tile.react-calendar__tile--active': {
    color: '$gs1 !important',
    bgColor: '$gs11 !important',
  },
})
