import React from 'react'
import { css, cx } from 'styled-system/css'
import type { SystemStyleObject } from 'styled-system/types'

type SpinnerProps = {
  className?: string
  css?: SystemStyleObject
}

// https://codepen.io/AlexWarnes/pen/jXYYKL
export const Spinner: React.FC<SpinnerProps> = ({ className, css: cssProp = {} }) => {
  return (
    <div className={cx(spinnerBox, css(cssProp), className)}>
      <div className={leoBorder1}>
        <div className={leoCore1} />
      </div>
      <div className={leoBorder2}>
        <div className={leoCore2} />
      </div>
    </div>
  )
}

const spinnerBox = css({
  width: '[300px]',
  height: '[300px]',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'transparent',
})

const leoBorder1 = css({
  position: 'absolute',
  width: '[150px]',
  height: '[150px]',
  padding: '[3px]',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '[50%]',
  opacity: 0.5,
  background: '[linear-gradient(0deg, {colors.$sec} 33%, {colors.$sec} 100%)]',
  animationName: 'spinnerSpin3D',
  animationDuration: '[1.8s]',
  animationTimingFunction: 'linear',
  animationIterationCount: 'infinite',
})

const leoCore1 = css({
  width: '[100%]',
  height: '[100%]',
  backgroundColor: '$sec_L',
  opacity: 0.5,
  borderRadius: '[50%]',
})

const leoBorder2 = css({
  position: 'absolute',
  width: '[150px]',
  height: '[150px]',
  padding: '[3px]',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '[50%]',
  opacity: 0.5,
  background: '[linear-gradient(0deg, {colors.$pri} 33%, {colors.$pri} 100%)]',
  animationName: 'spinnerSpin3D',
  animationDuration: '[2.2s]',
  animationTimingFunction: 'linear',
  animationIterationCount: 'infinite',
})

const leoCore2 = css({
  width: '[100%]',
  height: '[100%]',
  backgroundColor: '$pri_L',
  opacity: 0.5,
  borderRadius: '[50%]',
})
