'use client'

import { useEffect, useRef, useState } from 'react'

import { GoogleAd } from '../GoogleAd'

export const DynamicAd = ({ googleAdHorizontalConfig, horizontalCollapsableId }) => {
  const [isVisible, setIsVisible] = useState(false)
  const adRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsVisible(true)
          } else {
            setIsVisible(false)
          }
        })
      },
      { threshold: 0.1 }, // Trigger when 10% of the ad is visible
    )

    if (adRef.current) {
      observer.observe(adRef.current)
    }

    return () => {
      if (adRef.current) {
        observer.unobserve(adRef.current)
      }
    }
  }, [])

  return (
    <div ref={adRef} style={{ minHeight: '100px', margin: '0 auto' }}>
      {isVisible && (
        <GoogleAd
          {...googleAdHorizontalConfig}
          collapsableId={horizontalCollapsableId}
          css={{
            justifyContent: 'flex-start',
          }}
        />
      )}
    </div>
  )
}
