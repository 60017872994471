'use client'

import { Icon } from '@design-system/src/components/Icon'
import { EnterFullScreenIcon, ExitFullScreenIcon } from '@radix-ui/react-icons'
import { BlockPubArticleHeaderCustomPageData } from 'app/sites/[site]/[[...page]]/_utils/typescript-utils'
import { FC, ReactNode, useState } from 'react'
import { css } from 'styled-system/css'

import { EReaderCarousel } from './EReaderCarousel'

export const EReaderWrapper: FC<{
  children: ReactNode
  carousel: BlockPubArticleHeaderCustomPageData['carousel']
}> = ({ children, carousel }) => {
  const [isEReaderOpen, setIsEReaderOpen] = useState(false)
  const [initialSlideIndex, setInitialSlideIndex] = useState(0)

  const toggleEReaderMode = () => {
    if (isEReaderOpen) {
      // remove overflow hidden to body
      document.body.style.overflow = 'auto'
    } else {
      // add overflow hidden to body
      document.body.style.overflow = 'hidden'
      // Set initial slide index
      const elswiperSlideActive = document.querySelector('.swiper-slide-active')
      if (elswiperSlideActive) {
        // elswiperSlideActive has an attribute data-swiper-slide-index. Get the attribute value
        const activeSlideIndex = elswiperSlideActive.getAttribute('data-swiper-slide-index')
        if (activeSlideIndex) setInitialSlideIndex(parseInt(activeSlideIndex))
      }
    }
    setIsEReaderOpen(!isEReaderOpen)
  }

  if (!carousel) return <>{children}</>

  return isEReaderOpen ? (
    <div className={fullScreenWrapper}>
      <EReaderCarousel carousel={carousel} initialSlide={initialSlideIndex} />
      <Icon
        reactIcon={<ExitFullScreenIcon />}
        size={iconResponsiveSizes}
        className={css(toggleEReaderModeIconStyle)}
        onClick={toggleEReaderMode}
      />
    </div>
  ) : (
    <div style={{ position: 'relative' }}>
      {children}
      <Icon
        reactIcon={<EnterFullScreenIcon />}
        size={iconResponsiveSizes}
        css={toggleEReaderModeIconStyle}
        onClick={toggleEReaderMode}
      />
    </div>
  )
}
const iconResponsiveSizes = {
  base: '18',
  bp1: '20',
  bp2: '22',
  bp3: '24',
} as const

const fullScreenWrapper = css({
  position: 'fixed',
  inset: '0',
  zIndex: 10000,
  background: '$gs12',
  overflow: 'auto',
  // display: 'flex',
  // flexDirection: 'column',
  // alignItems: 'center',
  // justifyContent: 'center',
})

const toggleEReaderModeIconStyle = {
  position: 'absolute',
  zIndex: 1,
  right: '$4',
  padding: '$2',
  cursor: 'pointer',
  borderRadius: '$round',
  borderWidth: '$1',
  borderStyle: 'solid',
  borderColor: '$gs8',
  backgroundColor: '$gs1',
  opacity: 0.8,
  top: '$3',
  bp2: {
    top: '$4',
  },
  _hover: {
    opacity: 1,
    transitionProperty: 'opacity',
    transitionDuration: '$normal',
    transitionTimingFunction: 'in-out',
  },
} as const
