'use client'

// !!! DO NOT REMOVE USE CLIENT. Otherwise, the slug passed in blockCustomData will not be the correct one.
import { Box } from '@design-system/src/components/Box'
import { ShoImage } from '@design-system/src/components/ShoImage'
import {
  BlockPubHeaderMenuCustomPageData,
  BlockViewProps,
} from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import React from 'react'

import { isAdvertisementVisible } from '../../../_utils/ad-utils'
import { GoogleAd } from '../../GoogleAd'

const collapsableId = 'block-pub-header-menu-leaderboard-ad'

export const LeaderboardAd: React.FC<
  BlockViewProps<{
    ShapeOfCustomPropsDerivedFromPageData: BlockPubHeaderMenuCustomPageData
  }>
> = props => {
  const {
    blockCustomData: { isAdVisible: userHasChosenLeaderboardToBeVisible, googleAdConfig, alternativeGtmCustomAd },
  } = props
  const advertisementIsVisible = isAdvertisementVisible(googleAdConfig)

  const imgSrc = googleAdConfig?.customAds?.imgSrc
  const imgHeight = googleAdConfig?.customAds?.imgHeight
  const imgWidth = googleAdConfig?.customAds?.imgWidth
  // we show the ad at the top placement on the homepage and bottom placement on all other pages
  const showBlock = userHasChosenLeaderboardToBeVisible && advertisementIsVisible
  // console.log('alternativeGtmCustomAd in LeaderboardAd', alternativeGtmCustomAd)

  return showBlock ? (
    <Box
      css={{
        backgroundColor: '$gs4',
        overflow: 'auto',
      }}
      data-sho-block-id={props.blockId}
      data-sho-block-name="BlockPubHeaderMenu-LeaderboardAd"
      id={collapsableId}>
      {googleAdConfig?.adSystem === 'googleAdManager' && (
        <GoogleAd
          {...googleAdConfig}
          type="horizontal"
          // collapsableId={collapsableId}
          css={{
            py: '$4',
            boxSizing: 'content-box',
            justifyContent: 'flex-start',
            '& div': {
              margin: '[0 auto]',
            },
          }}
          alternativeGtmCustomAd={alternativeGtmCustomAd}
        />
      )}
      {googleAdConfig?.adSystem === 'custom' && imgSrc && imgHeight && imgWidth && (
        <a href={googleAdConfig.customAds?.href || ''} target="_blank" rel="noreferrer">
          <ShoImage
            src={imgSrc}
            alt="google-ads"
            width={imgWidth}
            height={imgHeight}
            priority={true}
            imageCss={{
              display: 'block',
              objectFit: 'contain',
              margin: '[0 auto]',
              width: 'auto',
              height: 'auto',
              minWidth: '$full',
              maxWidth: '$full',
              maxHeight: '[70vh]',
            }}
            sizes="(min-width: 77.5em) 1120px, (min-width: 37.5em) calc(100vw - 64px), calc(100vw - 48px)"
          />
        </a>
      )}
    </Box>
  ) : null
}
