import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Text } from '@design-system/src/components/Text'
import 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import { FC } from 'react'

export const CustomerSerivce: FC<{ customerServiceNumber?: string; customerServiceNumberURI?: string }> = ({
  customerServiceNumber,
  customerServiceNumberURI,
}) => {
  return customerServiceNumber && customerServiceNumberURI ? (
    <CustomFlex justify="center" css={{ mt: '$10' }}>
      <Text variant="caption" css={{ color: '$gs11' }}>
        If you need help, please contact Customer Service at
        <a href={customerServiceNumberURI} target="_blank">
          <Text variant="caption" css={{ color: '$pri', display: 'inline', textWrap: 'nowrap' }}>
            &nbsp;{customerServiceNumber}&nbsp;.
          </Text>
        </a>
      </Text>
    </CustomFlex>
  ) : null
}
