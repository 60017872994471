import { abbreviateMonthName, monthNames } from '@design-system/src/utils/date-utils'

export const formatDateForStoryPreview = ({
  date,
  isMonthNameAbbreviated,
  hideYearIfCurrentYear = true,
}: {
  date: string | number
  isMonthNameAbbreviated: boolean
  hideYearIfCurrentYear?: boolean
}): string => {
  const d = new Date(date)
  const day = d.getUTCDate()
  const month = d.getUTCMonth() //months from 1-12
  const year = d.getUTCFullYear()
  const isCurrentYear = year === new Date().getUTCFullYear()
  const monthName = monthNames[month]
  const monthNameToUse = isMonthNameAbbreviated ? abbreviateMonthName(monthName) : monthName
  const yearToUse = hideYearIfCurrentYear ? (isCurrentYear ? '' : `, ${year}`) : `, ${year}`

  return `${monthNameToUse} ${day}${yearToUse}`
}
