import { Box } from '@design-system/src/components/Box'
import { Text } from '@design-system/src/components/Text'


export const RemoveFilterButton = ({
    onClick,
    label,
    id,
    category,
  }: {
    onClick: () => void
    label: string
    id: string
    category: string
  }) => {
    return (
      <Text key={id} variant="body2" css={{ display: 'inline-flex', gap: '$2' }}>
        <Box css={{ color: '$gs11' }}>{category}:</Box>
        <Box
          css={{
            _hover: {
              color: '$gs11',
              cursor: 'pointer',
              textDecoration: 'line-through',
              transitionProperty: 'all',
              transitionDuration: '$normal',
            },
          }}
          onClick={onClick}>
          {label}
        </Box>
      </Text>
    )
  }
  