'use client'

import { CustomDivider } from '@design-system/src/components/CustomDivider'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Gutter } from '@design-system/src/components/Gutter'
import { Text } from '@design-system/src/components/Text'
import React from 'react'

import { isAdvertisementVisible } from '../../_utils/ad-utils'
import { BlockEmailCaptureCustomPageData, BlockViewProps } from '../../_utils/typescript-utils'
import { EmailCapture } from '../EmailCapture'
import { GoogleAd } from '../GoogleAd'

const collapsableId = 'block-email-capture-ad'

export const BlockEmailCapture: React.FC<
  BlockViewProps<{
    ShapeOfCustomPropsDerivedFromPageData: Omit<BlockEmailCaptureCustomPageData, 'variation'>
  }>
> = props => {
  const { googleAdConfig, showAd } = props.blockCustomData
  const advertisementIsVisible = googleAdConfig ? isAdvertisementVisible(googleAdConfig) : false
  return (
    <div data-sho-block-name="BlockEmailCapture" data-sho-block-id={props.blockId}>
      {showAd && advertisementIsVisible && googleAdConfig && (
        <div id={collapsableId}>
          <Gutter modificiations="noBottomPadding" css={{ pb: '$5' }}>
            <CustomFlex direction="column" gap={'5'}>
              <CustomDivider orientation="horizontal" size="medium" css={{ color: '$gs7' }} />
              <Text variant="overline" oll={false} css={{ color: '$gs11', textAlign: 'center' }}>
                Advertisement
              </Text>
            </CustomFlex>
          </Gutter>
          <Gutter variant={'horizontal'} css={{ overflow: 'auto', mb: '$6', bp2: { mb: '$8' }, bp3: { mb: '$10' } }}>
            <GoogleAd {...googleAdConfig} collapsableId={collapsableId} />
          </Gutter>
        </div>
      )}
      <EmailCapture {...props.blockCustomData} />
    </div>
  )
}
