import { PORTAL_SUBSCRIPTION_MESSAGE } from 'app/sites/[site]/payment-portal-result/[email]/page'
import { useEffect } from 'react'
import { css } from 'styled-system/css'

import { BlockModal } from '../../../BlockModal'

type PaymentPortalModalProps = {
  modalIsOpen: boolean
  modalIsOpenChange: (value: boolean) => void
  transactionId: string
  setErrorMsg: (msg: string) => void
  handleSubscribeUserToPlan: (email?: string) => Promise<void>
  //   planName: string
  //   planPrice: number
  //   planFrequencyLabel: string
}

export const PaymentPortalModal: React.FC<PaymentPortalModalProps> = ({
  transactionId,
  modalIsOpen,
  modalIsOpenChange,
  setErrorMsg,
  handleSubscribeUserToPlan,
  //   planName,
  //   planPrice,
  //   planFrequencyLabel,
}) => {
  useEffect(() => {
    const randomId = Math.random().toString(36).substring(7)
    const handleMessage = async event => {
      if (event.data.source !== 'react-devtools-content-script' && event.data.source !== 'apollo-client-devtools') {
        console.log('event', event, randomId)
      }
      const portalSubscriptionResult = event.data as PORTAL_SUBSCRIPTION_MESSAGE
      switch (portalSubscriptionResult.message) {
        case 'SUBSCRIPTION_SUCCESS':
          await handleSubscribeUserToPlan(portalSubscriptionResult.email)
          modalIsOpenChange(false)
          break
        case 'SUBSCRIPTION_FAILURE':
          setErrorMsg('There was an error processing your payment. Please try again later.')
          modalIsOpenChange(false)
          break
      }
    }

    console.log('adding event listener')
    // check if window already has an event listener for message
    // if it does, remove it before adding a new one
    window.removeEventListener('message', handleMessage)
    window.addEventListener('message', handleMessage)
    // Cleanup on unmount
    return () => {
      console.log('removing event listener')
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  const protectPayPortalEndpoint =
    process.env.NEXT_PUBLIC_STAGE === 'dev'
      ? 'https://protectpaytest.propay.com/hpp/v2/'
      : 'https://protectpay.propay.com/hpp/v2/'

  const protectpayHppUrl = `${protectPayPortalEndpoint}/${transactionId}`

  return (
    <BlockModal
      openModal={modalIsOpen}
      onCloseModalCallback={modalIsOpenChange}
      title="Secure Payment"
      css={{
        width: '[min(90vw, 400px) !important]',
        height: '[min(90vh, 500px)]',
      }}>
      {/* <Text variant="subtitle1" css={{ color: '$gs12', textAlign: 'center' }}>
        Please enter your payment information below.
      </Text> */}
      <iframe
        className={iframeStyles}
        src={protectpayHppUrl}
        // sandbox="allow-top-navigation allow-scripts allow-forms"
      />
    </BlockModal>
  )
}

const iframeStyles = css({
  mx: '$auto',
  // minWidth: '400px',
  height: '[calc(min(40vh, 400px))]',
  overflow: 'hidden',
})
