'use client'

import { Text } from '@design-system/src/components/Text'
import { FC, useMemo } from 'react'

import { MagicLinkStateType } from '../../../_utils/typescript-utils'

export const Greeting: FC<{
  magicLinkState: MagicLinkStateType
}> = ({ magicLinkState }) => {
  const greetingMsg = useMemo((): string => {
    if (magicLinkState === 'sent') return 'Check your inbox'
    const now = new Date()
    const hour = now.getHours()
    if (hour < 12) {
      return 'Good Morning,'
    } else if (hour < 18) {
      return 'Good Afternoon,'
    } else {
      return 'Good Evening,'
    }
  }, [magicLinkState])

  return (
    <Text variant="h2" css={{ color: '$gs12' }}>
      {greetingMsg}
    </Text>
  )
}
