import { getStripe } from '@design-system/src/utils/stripe-utils'
import { CreateCheckoutSessionBody } from 'app/api/stripe/create-checkout-session/route'
import { BlockPubSubscribeCustomPageData } from 'app/sites/[site]/[[...page]]/_utils/typescript-utils'

import { postData } from '../../../../_utils/rest-utils'

export const onSubscribeToStripePlanFactory =
  ({
    userHasASubscription,
    createSubscriberWithStripeAccountApiUrl,
    updateSubscriberWithStripeAccountApiUrl,
    stripePublishableKey,
    loadStripe,
    setSubmitBtnState,
    priceId,
    setErrorMsg,
    couponId,
  }: {
    setSubmitBtnState: React.Dispatch<React.SetStateAction<'default' | 'waiting' | undefined>>
    userHasASubscription: boolean
    createSubscriberWithStripeAccountApiUrl: BlockPubSubscribeCustomPageData['createSubscriberWithStripeAccountApiUrl']
    updateSubscriberWithStripeAccountApiUrl: BlockPubSubscribeCustomPageData['updateSubscriberWithStripeAccountApiUrl']
    stripePublishableKey: string
    loadStripe: BlockPubSubscribeCustomPageData['loadStripe']
    priceId: string
    setErrorMsg: React.Dispatch<React.SetStateAction<string>>
    couponId?: string
  }) =>
  async ({ email }: { email?: string }) => {
    setSubmitBtnState('waiting')
    if (userHasASubscription) {
      await changeSubscriptionPlan({
        updateSubscriberWithStripeAccountApiUrl,
        setErrorMsg,
        setSubmitBtnState,
      })
    } else {
      await firstTimeSubscribing({
        priceId,
        email,
        createSubscriberWithStripeAccountApiUrl,
        stripePublishableKey,
        loadStripe,
        setErrorMsg,
        couponId,
        couponType: 'coupon',
      })
    }
    //  Subcribe the user (user has no current plans)
    setSubmitBtnState('default')
  }

const firstTimeSubscribing = async ({
  priceId,
  email,
  createSubscriberWithStripeAccountApiUrl,
  stripePublishableKey,
  loadStripe,
  setErrorMsg,
  couponId,
  couponType,
}: {
  priceId: string
  email: string | undefined
  createSubscriberWithStripeAccountApiUrl: string
  stripePublishableKey: string
  loadStripe: BlockPubSubscribeCustomPageData['loadStripe']
  setErrorMsg: React.Dispatch<React.SetStateAction<string>>
  couponId?: string
  couponType?: 'promotion_code' | 'coupon'
}) => {
  try {
    const data: CreateCheckoutSessionBody = {
      price: priceId,
      from: 'checkout',
      coupon: couponId,
      couponType,
      email,
    }
    const { accountId, sessionId } = await postData<
      CreateCheckoutSessionBody,
      {
        sessionId: string
        accountId: string
      }
    >({
      url: createSubscriberWithStripeAccountApiUrl,
      data,
    })
    const stripe = await getStripe(accountId, stripePublishableKey, loadStripe)
    if (stripe) {
      stripe.redirectToCheckout({ sessionId })
    } else {
      throw new Error('Failed to load stripe')
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const message =
      typeof error === 'object' && error?.cause?.name === 'USER_ALREADY_EXISTS'
        ? 'Subscription with email already exists.'
        : 'An error occurred. Please try again later or contact support.'
    setErrorMsg(message)
  }
}

const changeSubscriptionPlan = async ({
  updateSubscriberWithStripeAccountApiUrl,
  setErrorMsg,
  setSubmitBtnState,
}: {
  updateSubscriberWithStripeAccountApiUrl: string
  setErrorMsg: React.Dispatch<React.SetStateAction<string>>
  setSubmitBtnState: React.Dispatch<React.SetStateAction<'default' | 'waiting' | undefined>>
}) => {
  //  Open stripe portal to change subscription
  try {
    const { url, error } = await postData<
      {},
      {
        url: string
        error: {
          message: string
        }
      }
    >({
      url: updateSubscriberWithStripeAccountApiUrl,
      data: {},
    })
    if (error) {
      throw new Error(error.message)
    }
    window.location.assign(url)
  } catch (error) {
    setErrorMsg('An error occured while changing subscription plan. Please try again later.')
  }
  setSubmitBtnState('default')
}
