'use client'

import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Tag } from '@design-system/src/components/Tag'
import { Textfield } from '@design-system/src/components/Textfield'
import { useRouter } from 'next-nprogress-bar'
import Link from 'next/link'
import { FC, useCallback, useState } from 'react'
import { Divider } from 'styled-system/jsx'

export const SearchForTagInList: FC<{
  data: {
    name: string
    href: string
  }[]
  type: 'section' | 'tag'
}> = ({ data, type }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const router = useRouter()

  const onSearchTermChange = useCallback(e => {
    setSearchTerm(e.target.value)
  }, [])

  const dataFiltered = data
    ?.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
    .sort((a, b) => a.name.localeCompare(b.name))

  const placeholder = `Search ${type === 'section' ? 'section' : 'tag'}`

  return (
    <CustomFlex
      gap={{
        base: '5',
        bp1: '6',
        bp2: '8',
        bp3: '10',
      }}
      direction={'column'}>
      <Divider />
      <Textfield
        name="search"
        placeholder={placeholder}
        value={searchTerm}
        onChange={onSearchTermChange}
        fullWidth={true}
      />
      <CustomFlex gap="3" direction={'row'} wrap={'wrap'}>
        {dataFiltered?.map(item => (
          <Link key={item.href} prefetch={false} href={item.href}>
            <Tag id={item.href} label={humanReadableLabel(item.name)} />
          </Link>
        ))}
      </CustomFlex>
    </CustomFlex>
  )
}

function humanReadableLabel(name: string): string {
  return name
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}
