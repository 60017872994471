'use client'

import { Button } from '@design-system/src/components/Button'
import { Checkbox } from '@design-system/src/components/Checkbox'
import { CustomDivider } from '@design-system/src/components/CustomDivider'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Text } from '@design-system/src/components/Text'
import { WysiwygReader } from '@design-system/src/components/WysiwygReader'
import { dollarString } from '@design-system/src/utils/string-utils'
import { getStripe } from '@design-system/src/utils/stripe-utils'
import { loadStripe } from '@stripe/stripe-js/pure'
import { CreateCheckoutSessionForGiftBody } from 'app/api/stripe/create-checkout-session-for-gift/route'
import { FC, useCallback, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { API_ROUTES_NEXT_PUBLIC_SITE } from 'src/utils/route-utils'
import { cx } from 'styled-system/css'
import { cva } from 'styled-system/css/cva'

import { postData } from '../../../_utils/rest-utils'
import { BlockPubGiftCustomPageData } from '../../../_utils/typescript-utils'
import { DisplayErrorToUser } from './DisplayErrorToUser'
import { GetUserEmail, UserEmailFormInput } from './GetUserEmail'

type FormInput = {
  giftProductId: string
  email: string
}

export const GiftPlan: FC<{
  planWithGiftsPrices: NonNullable<BlockPubGiftCustomPageData['plansWithGiftsPrices']>[0]
  totalNumberOfPlans: 1 | 2 | 3
  userEmail?: string
  stripePublishableKey: string
  customerServiceNumber?: string
}> = ({ planWithGiftsPrices, totalNumberOfPlans, userEmail, stripePublishableKey, customerServiceNumber }) => {
  const { name, subtitle, planSummary, benefits, prices, planProductId, clickSubscribeLinkOverrideMonthly } =
    planWithGiftsPrices
  const [callInProgress, setCallInProgress] = useState(false)
  const [userInfoModalIsOpen, setUserInfoModalIsOpen] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false)

  const cheapestGiftPrice = prices.reduce((min, plan) => Math.min(min, plan.priceInCents), Infinity)
  // const dropdownExposedPrices: DropdownExposedProps['items'] = prices.map(price => ({
  //   key: price.giftProductId,
  //   label: `${price.monthsGifted} months for ${dollarString.format(price.priceInCents / 100)}`,
  // }))

  const hasMultipleGiftOptions = prices.length > 1

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    register,
    formState: { errors, defaultValues },
  } = useForm<FormInput>({
    mode: 'onSubmit',
    defaultValues: { email: userEmail, giftProductId: hasMultipleGiftOptions ? '' : prices[0].giftProductId },
  })

  const onSubmit: SubmitHandler<FormInput> = async ({ giftProductId, email }) => {
    const giftPlanInfo = prices.find(price => price.giftProductId === giftProductId)
    if (!giftPlanInfo) {
      throw new Error('No gift plan info found')
    }
    try {
      setCallInProgress(true)
      const { accountId, sessionId } = await postData<
        CreateCheckoutSessionForGiftBody,
        {
          sessionId: string
          accountId: string
        }
      >({
        url: API_ROUTES_NEXT_PUBLIC_SITE.createCheckoutSessionForGiftApiUrl,
        data: {
          price: giftPlanInfo.priceId,
          email: email,
        },
      })
      const stripe = await getStripe(accountId, stripePublishableKey, loadStripe)
      if (stripe) {
        stripe.redirectToCheckout({ sessionId })
      } else {
        throw new Error('Failed to load stripe')
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const message =
        typeof error === 'object' && error?.cause?.name === 'USER_ALREADY_EXISTS'
          ? 'Subscription with email already exists.'
          : `An error occurred. Please try again later or contact customer support${customerServiceNumber ? `: ${customerServiceNumber}` : ''}.`
      setErrorMsg(message)
      setErrorModalIsOpen(true)
    } finally {
      setCallInProgress(false)
    }
  }

  const onUserEmailSubmitCallback = useCallback(
    (data: UserEmailFormInput) => {
      setValue('email', data.email)
      handleSubmit(onSubmit)()
    },
    [handleSubmit, onSubmit, setValue],
  )

  return (
    <ConditionalLinkWrapper href={clickSubscribeLinkOverrideMonthly}>
      <form
        onSubmit={e => {
          e.preventDefault()
          if (clickSubscribeLinkOverrideMonthly) {
            window.location.href = clickSubscribeLinkOverrideMonthly
          } else if (userEmail) {
            handleSubmit(onSubmit)(e)
          } else {
            setUserInfoModalIsOpen(true)
          }
        }}
        key={planProductId}
        className={cx(
          cardContainer({
            totalNumberOfPlans,
          }),
        )}>
        <Text variant="body1">{name}</Text>
        {hasMultipleGiftOptions ? (
          <Text variant="h5">Starting at {dollarString.format(cheapestGiftPrice / 100)}</Text>
        ) : (
          <Text variant="h5">
            {prices[0].monthsGifted} month{prices[0].monthsGifted > 1 ? 's' : ''} for{' '}
            {dollarString.format(cheapestGiftPrice / 100)}
          </Text>
        )}
        {subtitle && <Text variant="body1">{subtitle}</Text>}
        {hasMultipleGiftOptions && (
          <CustomFlex direction="column" gap="1" align={'center'}>
            {prices.map(price => {
              return (
                <Checkbox
                  textVariant="body2"
                  key={price.giftProductId}
                  text={`${price.monthsGifted} months for ${dollarString.format(price.priceInCents / 100)}`}
                  iconType="indeterminate"
                  checked={watch('giftProductId') === price.giftProductId}
                  onChange={e => {
                    setValue('giftProductId', price.giftProductId, { shouldDirty: true })
                  }}
                />
              )
            })}
          </CustomFlex>
        )}
        <Button
          label="Gift the subscription"
          buttonType="submit"
          variant="primary"
          disabled={!watch('giftProductId')}
          buttonState={callInProgress ? 'waiting' : 'default'}
          fullWidth={true}
        />

        {planSummary && (
          <>
            <CustomDivider orientation="horizontal" size="small" css={{ color: '$gs6' }} />
            <Text variant="body2">{planSummary}</Text>
          </>
        )}
        {benefits && !!benefits.length && (
          <>
            <CustomDivider orientation="horizontal" size="small" css={{ color: '$gs6' }} />
            <WysiwygReader
              initialValue={benefits}
              css={{
                '& p': {
                  _before: { content: '"✓ "' },
                },
                textWrap: 'pretty',
              }}
            />
          </>
        )}
        <GetUserEmail
          isOpen={userInfoModalIsOpen}
          setIsOpen={setUserInfoModalIsOpen}
          onSubmitCallback={onUserEmailSubmitCallback}
          callInProgress={callInProgress}
        />
        <DisplayErrorToUser errorMsg={errorMsg} isOpen={errorModalIsOpen} setIsOpen={setErrorModalIsOpen} />
      </form>
    </ConditionalLinkWrapper>
  )
}

const ConditionalLinkWrapper = ({ href, children }) => {
  return href ? <a href={href}>{children}</a> : children
}

const cardContainer = cva({
  base: {
    bgColor: '$gs1',
    p: '$6',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    overflow: 'hidden',
    borderStyle: 'solid',
    borderRadius: '$4',
    width: '[342px]',
    maxWidth: '[calc(100dvw - 48px)]',
    textAlign: 'center',
    gap: '$4',
    transitionProperty: 'box-shadow, transform, border',
    transitionDuration: '$normal',
    _hover: {
      transitionProperty: 'box-shadow, transform, border',
      transitionDuration: '$normal',
    },
    bp1: {
      width: '[380px]',
    },
    bp3: {
      maxWidth: '[500px]',
    },
  },
  variants: {
    totalNumberOfPlans: {
      3: {
        bp3: {
          width: 'auto',
        },
      },
      2: {},
      1: {},
    },
  },
})
