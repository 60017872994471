import { Box } from '@design-system/src/components/Box'
import { Button } from '@design-system/src/components/Button'
import { Checkbox } from '@design-system/src/components/Checkbox'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Text } from '@design-system/src/components/Text'
import { Textfield } from '@design-system/src/components/Textfield'
import { ArrowRightIcon } from '@radix-ui/react-icons'
import React, { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { css } from 'styled-system/css'

import { BlockModal } from '../../../BlockModal'

export interface CancelationFormInput {
  reason: string
  priceIsTooHigh?: boolean
  iAmMoving?: boolean
  notEnoughTime?: boolean
  lookingForSpecificContent?: boolean
}

type CancelationReasonModalProps = {
  open: boolean
  setOpen: (value: boolean) => void
  setIsCancelSubscriptionModalOpen: (value: boolean) => void
  setFeedbackFormReason: (value: CancelationFormInput) => void
}

export const CancelationReasonModal: React.FC<CancelationReasonModalProps> = props => {
  const { open, setOpen, setIsCancelSubscriptionModalOpen, setFeedbackFormReason } = props

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CancelationFormInput>({ mode: 'onChange' })

  const [clarifyReasonChecked, setClarifyReasonChecked] = useState(false)
  const [failed, setFailed] = useState(false)

  const onSubmitCancelForm: SubmitHandler<CancelationFormInput> = async data => {
    try {
      setFeedbackFormReason(data)
      setOpen(false)
      setIsCancelSubscriptionModalOpen(true)
    } catch (error) {
      setFailed(true)
      console.log('Failed to submit form.')
    }
  }

  return (
    <BlockModal openModal={open} onCloseModalCallback={setOpen} title={'Cancel Subscription'}>
      <Box>
        <Text variant="body2" css={{ color: '$gs12', mb: '$6', textAlign: 'center' }}>
          Help us get better! Could you take a moment to share why you're canceling?
        </Text>
        <form
          onSubmit={handleSubmit(onSubmitCancelForm)}
          className={css({ backgroundColor: '$gs4', borderRadius: '$3', p: '$4' })}>
          <Box css={{ mb: '$10' }}>
            <Controller
              name="priceIsTooHigh"
              control={control}
              render={({ field }) => (
                <Checkbox textVariant="body2" {...field} text="Price is too high" css={{ mb: '$4' }} />
              )}
            />
            <Controller
              name="iAmMoving"
              control={control}
              render={({ field }) => <Checkbox textVariant="body2" {...field} text="I'm moving" css={{ mb: '$4' }} />}
            />
            <Controller
              name="notEnoughTime"
              control={control}
              render={({ field }) => (
                <Checkbox textVariant="body2" {...field} text="Too much to read, not enough time" css={{ mb: '$4' }} />
              )}
            />
            <Controller
              name="lookingForSpecificContent"
              control={control}
              render={({ field }) => (
                <Checkbox textVariant="body2" {...field} text="I was looking for specific content" css={{ mb: '$4' }} />
              )}
            />
            <Checkbox
              textVariant="body2"
              text="Other"
              onChange={(checked: boolean) => setClarifyReasonChecked(checked)}
            />
            {clarifyReasonChecked && (
              <Controller
                name="reason"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Please help us understand so that we can make improvements!',
                }}
                render={({ field }) => (
                  <Textfield
                    {...field}
                    rows={3}
                    fullWidth={true}
                    css={{ mt: '$2' }}
                    floatingLabel="* Can you please help us understand why you are canceling?"
                  />
                )}
              />
            )}
          </Box>
          <Button variant="primary" label="Submit" fullWidth={true} buttonType="submit" />
          {failed && (
            <CustomFlex
              justify="center"
              align="center"
              css={{ py: '$2', borderRadius: '$3', backgroundColor: '$ale', mt: '$4', p: '$2' }}>
              <Text variant="caption" css={{ color: '$aleText' }}>
                Sorry, we couldn't submit your feedback. Please try again later.
              </Text>
            </CustomFlex>
          )}
        </form>
        <Button
          variant="tertiary"
          label="Skip"
          rightReactIcon={<ArrowRightIcon />}
          fullWidth={true}
          css={{ mt: '$4' }}
          onClick={() => {
            setOpen(false)
            setIsCancelSubscriptionModalOpen(true)
          }}
        />
      </Box>
    </BlockModal>
  )
}
