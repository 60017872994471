import { Button } from '@design-system/src/components/Button'
import { Text } from '@design-system/src/components/Text'
import { Textfield } from '@design-system/src/components/Textfield'
import { FC, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { API_ROUTES_NEXT_PUBLIC_SITE } from 'src/utils/route-utils'

import { postData } from '../../../../_utils/rest-utils'

type FeedbackFormInput = { feedback: string }

export const FeedbackForm: FC<{}> = props => {
  const [submitFeedbackApiCallInProgress, setSubmitFeedbackApiCallInProgress] = useState(false)
  const [showFeedbackFailureErrorMsg, setShowFeedbackFailureErrorMsg] = useState(false)
  const [feedbackMsgSentSuccess, setFeedbackMsgSentSuccess] = useState(false)

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FeedbackFormInput>({ mode: 'onSubmit' })

  const onSubmitFeedbackForm: SubmitHandler<FeedbackFormInput> = async formData => {
    setSubmitFeedbackApiCallInProgress(true)
    setShowFeedbackFailureErrorMsg(false)
    const data = new URLSearchParams()
    data.append('feedback', formData.feedback)
    try {
      await postData({
        url: API_ROUTES_NEXT_PUBLIC_SITE.feedbackFormApiUrl,
        data,
        errorMsg: 'Failed to submit feedback.',
      })
      setFeedbackMsgSentSuccess(true)
    } catch (error) {
      console.error('error submitting feedback', error)
      setShowFeedbackFailureErrorMsg(true)
    } finally {
      setSubmitFeedbackApiCallInProgress(false)
    }
  }

  const onError = (errors: any) => {
    console.error('errors', errors)
  }

  return (
    <div>
      <Text variant="subtitle1" css={{ color: '$gs12', textAlign: 'center', mb: '$4' }}>
        * Suggestions and Feedback
      </Text>
      {feedbackMsgSentSuccess ? (
        <Text css={{ color: '$sucText', backgroundColor: '$suc', textAlign: 'center', py: '$5' }}>
          Your feedback has been sent. Thank you!
        </Text>
      ) : (
        <form onSubmit={handleSubmit(onSubmitFeedbackForm, onError)}>
          <Controller
            name="feedback"
            control={control}
            defaultValue=""
            rules={{
              required: 'Please provide your feedback',
            }}
            render={({ field }) => (
              <Textfield
                {...field}
                rows={3}
                fullWidth={true}
                reactFormErrors={errors}
                placeholder="Help us improve our system by proving any necessary feedback!"
              />
            )}
          />
          <Button
            css={{
              mt: '$4',
            }}
            variant="tertiary"
            label="Submit Feedback"
            fullWidth={true}
            buttonState={submitFeedbackApiCallInProgress ? 'waiting' : 'default'}
            buttonType="submit"
          />
          {showFeedbackFailureErrorMsg && (
            <Text variant="caption" css={{ color: '$ale', textAlign: 'center' }}>
              An error occurred while sending your feedback. Please try again later.
            </Text>
          )}
        </form>
      )}
    </div>
  )
}
