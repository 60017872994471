export const postData = async <D = unknown, R = unknown>({
  url,
  data,
  credentials = 'same-origin',
  errorMsg,
  mode = 'cors',
}: {
  url: string
  data: D
  errorMsg?: string
  credentials?: RequestCredentials
  mode?: RequestMode
}): Promise<R> => {
  const res = await fetch(url, {
    method: 'POST',
    credentials,
    body: JSON.stringify(data),
    mode,
  })

  if (res.status !== 200) {
    const resBody = await res.json()
    console.log('POST ERROR', res, resBody)
    const errorMessage = errorMsg || resBody?.error?.message || 'Error in POST request.'
    throw new Error(errorMessage, {
      cause: {
        name: resBody?.error?.code,
        message: resBody?.error?.message,
      },
    })
  }
  return res.json()
}

// http://site_81db4142-1b67-4af2-ac74-1dec93d81b71.localhost:3001/payment-portal-result/michael%40pubgen.ai?result=Success
