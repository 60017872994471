'use client'

import { Button, type ButtonProps } from '@design-system/src/components//Button'
import { CustomFlex } from '@design-system/src/components//CustomFlex'
import { Text } from '@design-system/src/components//Text'
import { Textfield } from '@design-system/src/components//Textfield'
import { Gutter } from '@design-system/src/components/Gutter'
import type { ResponseSuccess } from '@next-public-site/app/api/newsletter/subscribe/route'
import { useSession } from 'next-auth/react'
import {
  ColorVariationType,
  EmailCaptureType,
  MagicLinkStateType,
} from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import Link from 'next/link'
import React, { useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { PAGES_NEXT_PUBLIC_SITES } from 'src/utils/route-utils'
import { css, cva, cx } from 'styled-system/css'
import { Divider } from 'styled-system/jsx'

import { isUserSubscribedToNewsletter } from '../_utils/publication-utils'
import { validEmail } from '../_utils/validation-utils'

const DEFAULT_VARIATION = 'color' as ColorVariationType

export const EmailCapture: React.FC<EmailCaptureType> = ({
  privacyPolicy = '',
  title,
  subtitle,
  initialMagicLinkState = 'initial',
  newsletterId,
  variation = DEFAULT_VARIATION,
  onSubmitApiPath,
  css: cssProp,
  availableNewsletters,
  returnDivIfEmpty,
}) => {
  const { data: session, status } = useSession()
  const subscriber = session?.user
  const userNewsletterSubscriptionIds = session?.user.newsletterSubscriptions?.map(n => n.newsletterId) ?? []
  const isUserSubscribedToAllNewsletters = availableNewsletters
    ? availableNewsletters?.every(newsletter => userNewsletterSubscriptionIds.includes(newsletter.id))
    : true
  // console.log('isUserSubscribedToAllNewsletters', isUserSubscribedToAllNewsletters, {
  //   availableNewsletters,
  //   subscriber,
  // })
  interface IFormInput {
    email: string
  }

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IFormInput>({ mode: 'onSubmit' })

  const [magicLinkState, setMagiceLinkState] = useState<MagicLinkStateType>(initialMagicLinkState)
  const [submitBtnState, setSubmitBtnState] = useState<ButtonProps['buttonState']>('default')
  const [showError, setShowError] = useState(false)

  const onSubmitForm: SubmitHandler<IFormInput> = async formData => {
    if (!newsletterId) return
    setSubmitBtnState('waiting')
    const email = !subscriber?.email ? formData.email : subscriber.email
    const data = new URLSearchParams()
    data.append('email', email)
    data.append('newsletterIds', newsletterId)
    try {
      const response = await fetch(onSubmitApiPath, {
        method: 'post',
        body: data,
      })
      console.log('response', response)
      if (response.status === 200) {
        const body = (await response.json()) as ResponseSuccess
        console.log('body', body)
        const newsletterResponse = body.find(n => n.newsletterId === newsletterId)
        if (!newsletterResponse) {
          setMagiceLinkState('failed')
          return
        }
        switch (newsletterResponse.status) {
          case 'alreadySubscribed':
            setMagiceLinkState('alreadySubscribed')
            break
          case 'subscriptionCreated':
            setMagiceLinkState('subscriptionCreated')
            break
          case 'emailVerificationSent':
            setMagiceLinkState('sent')
            break
          default:
            console.warn('Unknown status in newsletter subscription response:', newsletterResponse.status)
            setMagiceLinkState('failed')
            break
        }
      } else {
        setMagiceLinkState('failed')
      }
    } catch (error) {
      setMagiceLinkState('failed')
    } finally {
      setSubmitBtnState('default')
    }
  }

  const getError = () => {
    if (showError) {
      if (errors?.email?.type === 'pattern') {
        return 'Not a valid email address.'
      } else if (errors?.email?.type === 'required') {
        return 'Email is required.'
      }
    }
  }

  const submissionResponseUserText = useMemo(() => {
    switch (magicLinkState) {
      case 'sent':
        return session?.user?.email
          ? 'Thank you! You have successfully signed up for the newsletter.'
          : 'Thank you! An email has been sent to confirm your newsletter.'
      case 'subscriptionCreated':
        return 'Thank you! You have successfully signed up for the newsletter.'
      case 'alreadySubscribed':
        return 'Thank you! You have already signed up for the newsletter.'
      default:
        return ''
    }
  }, [magicLinkState, session?.user?.email])

  if (!newsletterId)
    return returnDivIfEmpty ? (
      <Gutter variant={'horizontal'}>
        <Divider />
      </Gutter>
    ) : null

  if (isUserSubscribedToNewsletter(status, session, newsletterId)) {
    if (isUserSubscribedToAllNewsletters)
      return returnDivIfEmpty ? (
        <Gutter variant={'horizontal'}>
          <Divider />
        </Gutter>
      ) : null
    // Case for when user is already subscribed to main newsletter but is not subscribed to ALL the newsletters
    return (
      <Link href={PAGES_NEXT_PUBLIC_SITES.newsletters}>
        <EmailCaptureWrapper
          variation={variation}
          className={css({
            _hover: {
              opacity: '0.9',
              transitionDuration: '$normal',
              transitionTimingFunction: 'ease-in-out',
              '& span': {
                transform: 'translateY(-0.125rem)',
                transitionDuration: '$normal',
                transitionTimingFunction: 'ease-in-out',
              },
            },
          })}>
          <div
            className={cx(
              container({ variant: variation }),

              css(cssProp),
            )}>
            <Text
              className={captureText({
                style: variation,
              })}>
              Explore our full selection of newsletters to find your favorites.
            </Text>
          </div>
        </EmailCaptureWrapper>
      </Link>
    )
  }

  return (
    <EmailCaptureWrapper variation={variation}>
      <div className={cx(container({ variant: variation }), css(cssProp))}>
        {submissionResponseUserText ? (
          <CustomFlex direction="column" css={{ px: '$9' }}>
            <Text
              className={captureText({
                style: variation,
              })}
              variant="h4">
              {submissionResponseUserText}
            </Text>
            {privacyPolicy && (
              <Text
                className={captureText({
                  style: variation,
                })}
                variant="caption"
                css={{ mt: '$8' }}>
                {privacyPolicy}
              </Text>
            )}
          </CustomFlex>
        ) : (
          <>
            {title && (
              <Text
                className={captureText({
                  style: variation,
                })}
                variant="h4"
                css={{ mb: '$2' }}>
                {title}
              </Text>
            )}
            {subtitle && (
              <Text
                className={captureText({
                  style: variation,
                })}
                variant="body1"
                css={{ mb: '$8', opacity: '0.9' }}>
                {subtitle}
              </Text>
            )}
            {status === 'authenticated' ? (
              <Button
                variant="primary"
                label="Sign Up"
                fullWidth={true}
                onClick={handleSubmit(onSubmitForm)}
                buttonState={submitBtnState}
              />
            ) : (
              <form onSubmit={handleSubmit(onSubmitForm)}>
                <CustomFlex direction={{ base: 'column', bp1: 'row' }}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'Email is required.',
                      pattern: {
                        value: validEmail,
                        message: 'Please enter a valid email address.',
                      },
                    }}
                    render={({ field }) => (
                      <Textfield
                        {...field}
                        fullWidth={true}
                        css={{ mr: '$0', bp2: { mr: '$3' } }}
                        placeholder="* Email Address"
                        errorMsg={getError()}
                        onBlur={() => setShowError(true)}
                      />
                    )}
                  />
                  <Button
                    variant="primary"
                    label="Sign Up"
                    buttonType="submit"
                    buttonState={submitBtnState}
                    fullWidth={true}
                    css={{
                      mt: '$4',
                      height: '$12',
                      width: '$full',
                      ml: '$0',
                      bp1: {
                        mt: '$0',
                        width: 'fit',
                        ml: '$4',
                        '& >*': {
                          width: 'fit!', // this is a hack to make the button not take the full width. With stiches we could add style responsively, but not with Panda (Stitches code ref: fullWidth={{ '@initial': true, '@bp1': false }}
                        },
                      },
                      bp3: { ml: '$0' },
                    }}
                  />
                </CustomFlex>
              </form>
            )}
            {magicLinkState === 'failed' && (
              <CustomFlex
                css={{
                  py: '$2',
                  px: '$4',
                  bgColor: '$ale',
                  color: '$aleText',
                  mt: '$8',
                }}>
                <Text variant="caption">Oops! Something went wrong. Please try again.</Text>
              </CustomFlex>
            )}
            {privacyPolicy && (
              <Text
                className={captureText({
                  style: variation,
                })}
                variant="caption"
                css={{ mt: '$8' }}>
                {privacyPolicy}
              </Text>
            )}
          </>
        )}
      </div>
    </EmailCaptureWrapper>
  )
}

const EmailCaptureWrapper = ({
  children,
  variation = DEFAULT_VARIATION,
  className,
}: {
  children: React.ReactNode
  variation: ColorVariationType
  className?: string
}) => {
  const css =
    variation === 'light'
      ? { bgColor: '$gs1', boxShadow: '[0rem 0.1875rem 0.4625rem 0.0375rem {colors.$b11}]' }
      : {
          bgColor: '$pri_D',
        }

  return (
    <Gutter css={css} className={className}>
      {children}
    </Gutter>
  )
}

const container = cva({
  base: {
    boxSizing: 'border-box',
    margin: '[0px auto]',
    bp1: {
      maxWidth: '[650px]',
    },
  },
  variants: {
    variant: {
      dark: {
        backgroundColor: '$gs12',
      },
      color: {
        backgroundColor: '$pri_D',
      },
      light: {
        backgroundColor: '$gs1',
      },
    },
  },
  defaultVariants: {
    variant: DEFAULT_VARIATION,
  },
})

const captureText = cva({
  base: {
    color: '$gs1',
    textAlign: 'center',
  },
  variants: {
    style: {
      dark: {
        color: '$gs1',
      },
      color: {
        color: '$priText',
      },
      light: {
        color: '$gs12',
      },
    },
  },
})
