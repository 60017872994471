'use client'

import { CustomDivider } from '@design-system/src/components/CustomDivider'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Text } from '@design-system/src/components/Text'
import { useRouter } from 'next-nprogress-bar'
import React from 'react'

import { BlockCardsCustomPageData } from '../../../_utils/typescript-utils'

export const NavigationCards: React.FC<{
  cards: BlockCardsCustomPageData['cards']
}> = ({ cards }) => {
  const router = useRouter()

  const onClickAnchor = anchorId => event => {
    event.preventDefault() // Prevent the default anchor click behavior
    // Find the element with the given ID
    const element = document.getElementById(anchorId)
    if (element) {
      const elHeaderHeight = document
        .querySelector('[data-sho-block-name=BlockPubHeaderMenu-HeaderMain]')
        ?.getBoundingClientRect()?.height
      const yOffset = elHeaderHeight ? -elHeaderHeight : -140
      const yPosition = element.getBoundingClientRect().top + window.scrollY + yOffset
      window.scrollTo({ top: yPosition, behavior: 'smooth' })
      // router.push(`#${anchorId}`)
      router.replace(`#${anchorId}`, { scroll: false })
    }
  }

  return (
    <CustomFlex gap={'5'} direction={'column'} css={{ pb: '$8' }}>
      <CustomDivider css={customDividerStyle} />
      <CustomFlex gap={'5'} direction={'row'}>
        {cards.map(card => (
          <Text key={card.id} variant={'body2'} isLink={true} onClick={onClickAnchor(card.id)}>
            {card.title}
          </Text>
        ))}
      </CustomFlex>
      <CustomDivider css={customDividerStyle} />
    </CustomFlex>
  )
}

const customDividerStyle = {
  backgroundColor: '$gs10',
} as const
