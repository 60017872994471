'use client'

import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { CustomGrid } from '@design-system/src/components/CustomGrid'
import { ShoImage } from '@design-system/src/components/ShoImage'
import { Text } from '@design-system/src/components/Text'
import { Textfield } from '@design-system/src/components/Textfield'
import Link from 'next/link'
import { FC, useCallback, useState } from 'react'
import { css } from 'styled-system/css'
import { Divider } from 'styled-system/jsx'

export const SearchForAuthorInList: FC<{
  data: {
    picture?: string
    pictureHeight?: number
    pictureWidth?: number
    position?: string
    name: string
    href: string
  }[]
}> = ({ data }) => {
  const [searchTerm, setSearchTerm] = useState('')

  const onSearchTermChange = useCallback(e => {
    setSearchTerm(e.target.value)
  }, [])

  const dataFiltered = data
    ?.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
    .sort((a, b) => a.name.localeCompare(b.name))
    // place all those that have image, imageHeight, imageWidth first
    .sort((a, b) => {
      if (a.picture && a.pictureHeight && a.pictureWidth) {
        return -1
      }
      if (b.picture && b.pictureHeight && b.pictureWidth) {
        return 1
      }
      return 0
    })

  return (
    <CustomFlex
      gap={{
        base: '5',
        bp1: '6',
        bp2: '8',
        bp3: '10',
      }}
      direction={'column'}>
      <Divider />
      <Textfield
        name="search"
        placeholder="Search for writer"
        value={searchTerm}
        onChange={onSearchTermChange}
        fullWidth={true}
      />
      <CustomGrid
        gap={'medium'}
        columns={{
          base: '1',
          bp1: '2',
          bp2: '4',
          bp3: '5',
        }}>
        {dataFiltered?.map(item => (
          <Link key={item.href} className={container} href={item.href} prefetch={false}>
            {item.picture && item.pictureHeight && item.pictureWidth && (
              <div className={imageContainer}>
                <ShoImage
                  src={item.picture}
                  alt={item.name}
                  width={item.pictureWidth}
                  height={item.pictureHeight}
                  sizes="(min-width: 87.5em) 200px, (min-width: 77.5em) 270px, (min-width: 56.5625em) 404px, calc(100vw - 48px)"
                  className={imageStyled}
                />
              </div>
            )}
            <Text variant="body2" css={{ wordBreak: 'break-word' }}>
              {item.name}
            </Text>
            {item.position && (
              <Text variant="caption" css={{ wordBreak: 'break-word' }}>
                {item.position}
              </Text>
            )}
          </Link>
        ))}
      </CustomGrid>
    </CustomFlex>
  )
}

const container = css({})

const imageContainer = css({
  mb: '$2',
  width: '100%',
  height: 0,
  bgColor: '$gs12',
  paddingBottom: '100%',
  borderRadius: '$2',
  position: 'relative',
})

const imageStyled = css({
  position: 'absolute',
  inset: 0,
  width: '100%',
  height: '100%',
  objectFit: 'contain',
})
