'use client'

import { Box } from '@design-system/src/components/Box'
import { CustomDivider } from '@design-system/src/components/CustomDivider'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Gutter } from '@design-system/src/components/Gutter'
import { Text } from '@design-system/src/components/Text'
import { capitalizeFirstLetter } from '@design-system/src/utils/string-utils'
import { BlockPubSearchCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import dynamic from 'next/dynamic'
import React, { Suspense, useReducer } from 'react'
import { formatDateForStoryPreview } from 'src/utils/date-utils'

import { TypesenseArticle } from '@models/typesense'
import { fromUnixToUTC } from '@shared/util'

import { BlockViewProps } from '../../../_utils/typescript-utils'
import { ArticlePreviewHorizontal } from '../../ArticlePreviewHorizontal'
import { GoogleAd } from '../../GoogleAd'
import { PublicationTypesenseFilters } from './PublicationTypesenseFilters'

const InstantSearchBlock = dynamic(() => import('./InstantSearchBlock').then(module => module.InstantSearchBlock))
const Configure = dynamic(() => import('react-instantsearch-core').then(module => module.Configure))

const InfiniteHits = dynamic(() => import('./InfiniteHits').then(module => module.InfiniteHits))
const TypesenseSearchBox = dynamic(() => import('./TypesenseSearchBox').then(module => module.TypesenseSearchBox))
let timerId: NodeJS.Timeout

const collapsableId = 'block-pub-search'

type ValuePiece = Date | null
export type Value = ValuePiece | [ValuePiece, ValuePiece]

export type State = {
  dateValues: {
    fromDate: number | null
    toDate: number | null
  }
  datePickerValues: Value
}

const initialState: State = {
  dateValues: {
    fromDate: null,
    toDate: null,
  },
  datePickerValues: [new Date(), new Date()],
}

export type Action =
  | {
      type: 'setDateValues'
      payload: {
        fromDate: number | null
        toDate: number | null
      }
    }
  | {
      type: 'setDatePickerValues'
      payload: Value
    }
  | {
      type: 'resetDates'
    }
  | {
      type: 'resetAll'
    }

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'setDateValues':
      return {
        ...state,
        dateValues: action.payload,
      }
    case 'setDatePickerValues':
      return {
        ...state,
        datePickerValues: action.payload,
        dateValues: {
          fromDate: action.payload?.[0] ? Math.floor(action.payload[0].getTime() / 1000) : null,
          toDate: action.payload?.[1] ? Math.floor(action.payload[1].getTime() / 1000) : null,
        },
      }
    case 'resetDates':
      return {
        ...state,
        dateValues: initialState.dateValues,
        datePickerValues: initialState.datePickerValues,
      }
    case 'resetAll':
      return initialState
    default:
      console.warn('Unknown action type in reducer', action)
      return state
  }
}

export const BlockPubSearch: React.FC<
  BlockViewProps<{ ShapeOfCustomPropsDerivedFromPageData: BlockPubSearchCustomPageData }>
> = props => {
  const {
    typesenseHost,
    typesenseSearchKey,
    instantSearchIndexName,
    googleAdConfig,
    imgPlaceholder,
    imgPlaceholderWidth,
    imgPlaceholderHeight,
    publicationName,
  } = props.blockCustomData
  const [state, dispatch] = useReducer(reducer, initialState)
  const toDateToUse = state.dateValues.toDate || Math.floor(Date.now() / 1000)

  return (
    <Box data-sho-block-name="BlockPubSearch" data-sho-block-id={props.blockId} css={{ position: 'relative' }}>
      <Suspense fallback={<></>}>
        <InstantSearchBlock
          typesenseHost={typesenseHost}
          typesenseSearchKey={typesenseSearchKey}
          instantSearchIndexName={instantSearchIndexName}>
          <Configure
            // @ts-ignore
            hitsPerPage={50}
            filters={
              state.dateValues.fromDate
                ? `releaseDate:[${state.dateValues.fromDate}..${toDateToUse}]`
                : `releaseDate:<${toDateToUse}`
            }
            attributesToRetrieve={[
              'title',
              'summary',
              'date',
              'articleHref',
              'src',
              'section',
              'image',
              'imageHeight',
              'imageWidth',
              'slug',
              'releaseDate',
              'authors',
            ]}
            facetFilters={['type:Article']}
          />
          <Gutter css={{ bgColor: '$gs3', py: '$5' }} variant={'horizontal'}>
            <TypesenseSearchBox
              withVariant="full"
              placeholder={`Search ${publicationName ? capitalizeFirstLetter(publicationName) : ''}`}
              queryHook={(query, search) => {
                clearTimeout(timerId)
                timerId = setTimeout(() => {
                  search(query)
                }, 1000)
              }}
            />
          </Gutter>
          <PublicationTypesenseFilters state={state} dispatch={dispatch} />
          <Gutter css={{ bgColor: '$gs1' }}>
            <h1>
              <Text
                css={{
                  color: '$gs11',
                  mb: '$10',
                  bp2: {
                    mb: '$12',
                  },
                }}
                oll={false}
                variant="h6">
                LATEST <span style={{ visibility: 'hidden' }}>Stories</span>
              </Text>
            </h1>
            <CustomFlex
              direction="column"
              gap={'10'}
              css={{
                bp2: {
                  flexDirection: 'row',
                },
              }}>
              <Box css={{ width: '$full' }}>
                <InfiniteHits
                  hitComponent={({ hit, isLast }: { hit: TypesenseArticle; isLast: boolean }) => {
                    return (
                      <CustomFlex direction="column" justify="between" key={hit.slug}>
                        <ArticlePreviewHorizontal
                          imgPlaceholder={imgPlaceholder}
                          imgPlaceholderWidth={imgPlaceholderWidth}
                          imgPlaceholderHeight={imgPlaceholderHeight}
                          doNotTransformImageURL={false}
                          sectionName={hit.section?.name}
                          sectionHref={hit.section?.href}
                          src={hit.image?.url}
                          date={formatDateForStoryPreview({
                            date: fromUnixToUTC(hit.releaseDate),
                            isMonthNameAbbreviated: false,
                          })}
                          title={hit.title || ''}
                          summary={hit.summary || ''}
                          articleHref={hit.slug}
                          authors={{
                            size: 'small',
                            direction: 'row',
                            authors:
                              hit.authors?.map(author => ({
                                id: author.id,
                                imageSrc: author.imageHref,
                                name: author.name,
                                href: author.href,
                                title: author.position,
                              })) || [],
                          }}
                          imageHeight={hit.image?.height}
                          blurryPlaceholder={hit.image?.placeholder}
                          imageWidth={hit.image?.width}
                          css={{
                            '& img': {
                              objectFit: 'contain',
                              width: '$full',
                            },
                          }}
                          sizes="(max-width: 77.5em) 100vw, (max-width: 87.5em) 75vw, 800px"
                          placeholderSizes="(max-width: 77.5em) 100vw, (max-width: 87.5em) 75vw, 800px"
                        />
                        <CustomDivider
                          css={{
                            my: '$4',
                            display: isLast ? 'none' : 'block',
                            bp2: {
                              my: '$10',
                            },
                          }}
                        />
                      </CustomFlex>
                    )
                  }}
                />
              </Box>
              <div id={collapsableId}>
                <GoogleAd
                  {...googleAdConfig}
                  collapsableId={collapsableId}
                  swapAdAtInterval={true}
                  css={{
                    display: 'none !important',
                    bp2: {
                      minWidth: '[25% !important]',
                      width: '[25% !important]',
                      display: 'block !important',
                      position: 'sticky',
                      top: '[173px]',
                      height: 'fit',
                    },
                  }}
                />
              </div>
            </CustomFlex>
          </Gutter>
        </InstantSearchBlock>
      </Suspense>
    </Box>
  )
}
