'use client'

import { css, cx } from 'styled-system/css'
import type { SystemStyleObject } from 'styled-system/types'

import { Box } from '../Box'
import { Element } from './Element'
import { Leaf } from './Leaf'
import { SlateElementType, WysiwygElement } from './types'

export interface WisiwigReaderProps {
  /**
   * Set initial value for wisiwig.
   */
  initialValue: WysiwygElement[]

  /**
   * css property for wisiwig.
   */
  css?: SystemStyleObject

  className?: string
}

export const WysiwygReader: React.FC<WisiwigReaderProps> = ({
  initialValue = [],
  css: cssProp = {},
  className,
  ...props
}) => {
  const element: WysiwygElement = {
    type: 'editor',
    children: initialValue,
  }

  return (
    <Box className={cx(css(wrapperStyle, cssProp), className)} {...props}>
      <Render {...element} />
    </Box>
  )
}

const Render: React.FC<WysiwygElement> = (props: WysiwygElement) => {
  let children
  if ('children' in props) {
    children = props.children satisfies WysiwygElement[]
  }
  const type = props.type
  if (type && children && children.length > 0) {
    const element: SlateElementType = {
      attributes: {},
      element: props,
      children: null,
      isInEditMode: false,
    }
    return (
      <Element {...element} key={Math.random() * 10000}>
        {children && children.map(element => Render(element))}
      </Element>
    )
  }
  if ('text' in props) {
    const leaf = {
      attributes: {},
      leaf: { ...props },
      children: [props.text],
    }
    return <Leaf {...leaf} key={Math.random() * 10000} />
  }
  return null
}

const wrapperStyle = css.raw({
  height: 'auto',
  boxSizing: 'border-box',
  width: '$full',
  '& table': {
    display: 'block',
    overflowX: '[auto]',
  },
})
