'use client'

import { WaitingWithData } from '@next-public-site/app/sites/[site]/[[...page]]/_components/WaitingWithData'
import { redirect } from 'next/navigation'
import { FC, useContext } from 'react'
import { loginStatusContext } from 'src/hoc/withLoginStatePublicSite'
import { PAGES_NEXT_PUBLIC_SITES } from 'utils/route-utils'

// TODO: make this SSR and add 'use client' to blocks that need it
export const ProtectedPageProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const loginStatus = useContext(loginStatusContext)
  // TODO: move this clientThemeForStitches to be ssr and add it to layout or providers or something (tbd)

  if (loginStatus === 'loggedOut') {
    redirect(PAGES_NEXT_PUBLIC_SITES.signIn)
  }

  if (loginStatus === 'loading') {
    return <WaitingWithData message="Authenticating..." />
  }

  return children
}
