'use client'

import { Box } from '@design-system/src/components/Box'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { DropdownMenu, DropdownMenuProps } from '@design-system/src/components/Dropdowns/DropdownMenu'
import { Gutter } from '@design-system/src/components/Gutter'
import { Icon } from '@design-system/src/components/Icon'
import { Text } from '@design-system/src/components/Text'
import { ChevronDownIcon } from '@radix-ui/react-icons'
import React, { useCallback, useState } from 'react'
import { UseMenuProps, useMenu } from 'react-instantsearch'
import { css, cva } from 'styled-system/css'
import { Divider } from 'styled-system/jsx'

import { Action, State } from '..'
import { DateRangeSelection } from './DateRangeSelection'
import { RemoveFilterButton } from './RemoveFilterButton'

type PublicationTypesenseFiltersProps = {
  state: State
  dispatch: React.Dispatch<Action>
}

export const PublicationTypesenseFilters: React.FC<PublicationTypesenseFiltersProps> = ({ state, dispatch }) => {
  const [showSpecificDateInMenu, setShowSpecificDateInMenu] = useState(false)
  const transformItems = useCallback<NonNullable<UseMenuProps['transformItems']>>(items => {
    const toReturn = items
      .map(item => ({
        ...item,
        key: item.value,
        label: item.label,
        labelAppendix: item.count.toLocaleString('en-US'),
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) satisfies DropdownMenuProps['options']
    return toReturn
  }, [])

  const {
    refine: refineSectionName,
    items: sectionNameItems,
    canRefine: canRefineSectionName,
  } = useMenu({
    attribute: 'section.name',
    transformItems,
    limit: 100,
  })
  const {
    refine: refineAuthorNames,
    items: authorItems,
    canRefine: canRefineAuthorNames,
  } = useMenu({
    attribute: 'authors.name',
    transformItems,
    limit: 100,
  })
  const {
    refine: refineTagNames,
    items: tagItems,
    canRefine: canRefineTagNames,
  } = useMenu({
    attribute: 'tags.name',
    transformItems,
    limit: 100,
  })

  const filterBySection = sectionNameItems.find(item => item.isRefined)?.value || ''
  const filterByAuthor = authorItems.find(item => item.isRefined)?.value || ''
  const filterByTag = tagItems.find(item => item.isRefined)?.value || ''
  const filterByDate = state.dateValues.fromDate || state.dateValues.toDate

  const onSelectDateRangeItem = useCallback((item: NonNullable<DropdownMenuProps['options']>[0]) => {
    switch (item.key) {
      case 'yesterday':
        setShowSpecificDateInMenu(false)
        dispatch({
          type: 'setDateValues',
          payload: {
            fromDate: Math.floor(new Date().setDate(new Date().getDate() - 1) / 1000),
            toDate: null,
          },
        })
        break
      case 'last7days':
        setShowSpecificDateInMenu(false)
        dispatch({
          type: 'setDateValues',
          payload: {
            fromDate: Math.floor(new Date().setDate(new Date().getDate() - 7) / 1000),
            toDate: null,
          },
        })
        break
      case 'last30days':
        setShowSpecificDateInMenu(false)
        dispatch({
          type: 'setDateValues',
          payload: {
            fromDate: Math.floor(new Date().setDate(new Date().getDate() - 30) / 1000),
            toDate: null,
          },
        })
        break
      case 'last365days':
        setShowSpecificDateInMenu(false)
        dispatch({
          type: 'setDateValues',
          payload: {
            fromDate: Math.floor(new Date().setDate(new Date().getDate() - 365) / 1000),
            toDate: null,
          },
        })
        break
      case 'allTime':
        setShowSpecificDateInMenu(false)
        dispatch({ type: 'resetDates' })
        break
      case 'custom':
        setShowSpecificDateInMenu(true)
        break
      default:
        console.warn('Unknown date range item', item)
        break
    }
  }, [])

  const resetDateRange = useCallback(() => {
    dispatch({ type: 'resetAll' })
  }, [])

  return (
    <>
      <Gutter css={{ bgColor: '$gs3', pb: '$5', position: 'relative', zIndex: '1' }} variant={'horizontal'}>
        <div className={css(filterDisplayContainerStyle)}>
          <CustomFlex
            gap={{
              base: '2',
              bp1: '3',
              bp2: '4',
              bp3: '5',
            }}
            wrap="wrap">
            <div>
              <DropdownMenu
                options={dateRangeDropdownMenuOptions}
                allowScrollWhenOpen={true}
                textVariant="body2"
                collisionPadding={10}
                maxHeight={650}
                onSelectMenuItem={onSelectDateRangeItem}
                sideUi={showSpecificDateInMenu && <DateRangeSelection state={state} dispatch={dispatch} />}>
                <Text variant="body2" className={filterDisplayItem({ isSelected: !!filterByDate })}>
                  Date Range
                  <Icon className={iconStyle} reactIcon={<ChevronDownIcon />} pointer={true} />
                </Text>
              </DropdownMenu>
            </div>
            {canRefineSectionName && (
              <Box css={{ zIndex: 1, position: 'relative' }}>
                <DropdownMenu
                  options={sectionNameItems as unknown as DropdownMenuProps['options']}
                  textVariant="body2"
                  collisionPadding={10}
                  onSelectMenuItem={item => {
                    refineSectionName(item.key)
                  }}>
                  <Text variant="body2" className={filterDisplayItem({ isSelected: !!filterBySection })}>
                    Section
                    <Icon className={iconStyle} reactIcon={<ChevronDownIcon />} pointer={true} />
                  </Text>
                </DropdownMenu>
              </Box>
            )}
            {canRefineAuthorNames && (
              <Box css={{ zIndex: 1, position: 'relative' }}>
                <DropdownMenu
                  options={authorItems as unknown as DropdownMenuProps['options']}
                  textVariant="body2"
                  collisionPadding={10}
                  onSelectMenuItem={item => {
                    refineAuthorNames(item.key)
                  }}>
                  <Text variant="body2" className={filterDisplayItem({ isSelected: !!filterByAuthor })}>
                    Writer
                    <Icon className={iconStyle} reactIcon={<ChevronDownIcon />} pointer={true} />
                  </Text>
                </DropdownMenu>
              </Box>
            )}
            {canRefineTagNames && (
              <Box css={{ zIndex: 1, position: 'relative' }}>
                <DropdownMenu
                  options={tagItems as unknown as DropdownMenuProps['options']}
                  textVariant="body2"
                  collisionPadding={10}
                  onSelectMenuItem={item => {
                    refineTagNames(item.key)
                  }}>
                  <Text variant="body2" className={filterDisplayItem({ isSelected: !!filterByTag })}>
                    Tag
                    <Icon className={iconStyle} reactIcon={<ChevronDownIcon />} pointer={true} />
                  </Text>
                </DropdownMenu>
              </Box>
            )}
          </CustomFlex>
        </div>
      </Gutter>

      {(filterByDate || filterByTag || filterBySection || filterByAuthor) && (
        <>
          <Gutter variant="short" css={{ bgColor: '$gs1' }}>
            <CustomFlex gap={'2'} css={{ padding: '0 $3 $3 $3' }}>
              {filterByDate && (
                <RemoveFilterButton
                  onClick={resetDateRange}
                  label={getFilterByDateLabel(state.dateValues.fromDate, state.dateValues.toDate)}
                  id={'date'}
                  category="Date"
                />
              )}
              {filterBySection && (
                <RemoveFilterButton
                  onClick={() => refineSectionName('')}
                  label={filterBySection}
                  id={filterBySection}
                  category="Section"
                />
              )}
              {filterByAuthor && (
                <RemoveFilterButton
                  onClick={() => refineAuthorNames('')}
                  label={filterByAuthor}
                  id={filterByAuthor}
                  category="Author"
                />
              )}
              {filterByTag && (
                <RemoveFilterButton
                  onClick={() => refineTagNames('')}
                  label={filterByTag}
                  id={filterByTag}
                  category="Tag"
                />
              )}
            </CustomFlex>
          </Gutter>
          <Gutter variant="horizontal" css={{ bgColor: '$gs1' }}>
            <Divider
              css={{
                borderColor: '$gs6',
              }}
            />
          </Gutter>
        </>
      )}
    </>
  )
}

const getFilterByDateLabel = (fromDate: number | null, toDate: number | null) => {
  if (!fromDate && !toDate) {
    return 'All Time'
  }
  if (fromDate && toDate) {
    return `${new Date(fromDate * 1000).toLocaleDateString()} - ${new Date(toDate * 1000).toLocaleDateString()}`
  }
  if (fromDate && !toDate) {
    // Dates in YYYY-MM-DD format. If use timestamps, exact figures like yesterday won't work if the UI needs to re-render. So this allows more flexibility.
    const fromDateDate = new Date(fromDate * 1000).toISOString().split('T')[0]
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
    const pastWeek = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0]
    const pastMonth = new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0]
    const pastYear = new Date(new Date().setDate(new Date().getDate() - 365)).toISOString().split('T')[0]

    if (fromDateDate === yesterday) {
      return 'Yesterday'
    } else if (fromDateDate === pastWeek) {
      return 'Past Week'
    } else if (fromDateDate === pastMonth) {
      return 'Past Month'
    } else if (fromDateDate === pastYear) {
      return 'Past Year'
    }

    return `Since ${new Date(fromDate * 1000).toLocaleDateString()}`
  }
  if (!fromDate && toDate) {
    return 'Before ' + new Date(toDate * 1000).toLocaleDateString()
  }
  return 'Unknown Date Range'
}

type DateRange = 'yesterday' | 'last7days' | 'last30days' | 'last365days' | 'allTime' | 'custom'

const dateRangeDropdownMenuOptions: DropdownMenuProps['options'] = [
  {
    label: 'Yesterday',
    key: 'yesterday',
  },
  {
    label: 'Past Week',
    key: 'last7days',
  },
  {
    label: 'Past Month',
    key: 'last30days',
  },
  {
    label: 'Past Year',
    key: 'last365days',
  },
  {
    label: 'Specific Dates',
    key: 'custom',
    disableCloseOnSelect: true,
  },
] satisfies { label: string; key: DateRange; disableCloseOnSelect?: boolean }[]

const filterDisplayContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  py: '$2',
  gap: '$2',
  '& > div': {
    flex: '0 1 auto',
    width: 'auto',
  },
} as const

const filterDisplayItem = cva({
  base: {
    padding: '$2',
    cursor: 'pointer',
    userSelect: 'none',
    borderRadius: '$3',
    display: 'flex',
    alignItems: 'flex-end',
    gap: '$1',
    color: '$gs12',
    backgroundColor: '$gs2',
    '&:hover': {
      backgroundColor: '$gs1',
    },
  },
  variants: {
    isSelected: {
      true: {
        border: '1px solid $secText',
        // backgroundColor: '$gs1',
      },
      false: {
        border: '1px solid transparent',
      },
    },
  },
  defaultVariants: {
    isSelected: false,
  },
})

const iconStyle = css({
  display: 'none',
  bp1: {
    display: 'flex',
  },
})
