import { Text } from '@design-system/src/components/Text'
import { FC } from 'react'

import { BlockModal } from '../../../BlockModal'

type ActiveSubscriptionModalProps = {
  modalIsOpen: boolean
  onCloseModalCallback: (value: boolean) => void
}

export const ActiveSubscriptionModal: FC<ActiveSubscriptionModalProps> = props => {
  return (
    <BlockModal
      openModal={props.modalIsOpen}
      onCloseModalCallback={props.onCloseModalCallback}
      title="You have an active subscription!">
      <Text variant="subtitle1" css={{ color: '$gs12', textAlign: 'center' }}>
        In order to delete your account you must first cancel your subscription via your subscription&nbsp;portal.
      </Text>
    </BlockModal>
  )
}
