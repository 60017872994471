import { Text } from '@design-system/src/components/Text'
import { FC } from 'react'

import { BlockModal } from '../../../BlockModal'

type DeleteAccountSuccessModalProps = {
  modalIsOpen: boolean
  onCloseModalCallback: (value: boolean) => void
}

export const DeleteAccountSuccessModal: FC<DeleteAccountSuccessModalProps> = props => {
  return (
    <BlockModal openModal={props.modalIsOpen} onCloseModalCallback={props.onCloseModalCallback} title="Account Deleted">
      <Text variant="subtitle1" css={{ color: '$gs12', textAlign: 'center' }}>
        Your account has been deleted. We’re sorry to see you&nbsp;go!
      </Text>
    </BlockModal>
  )
}
